import React, {useState, useEffect} from 'react'

import {Row, Col, Alert, Container} from 'react-bootstrap'
import {withRouter} from 'react-router'
import styled from 'styled-components'

import {SubTitle, Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'
import {Input} from '../../UI/Input/Input'

const SuggestionsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  &::before {
    content: '';
    border-radius: 2px;
    position: absolute;
    right: 1.6rem;
    top: -0.6rem;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    border-right: none;
  }
`

const SuggestionsListItem = styled.li`
  cursor: pointer;
  padding: 10px 20px;

  &:hover {
    text-decoration: underline;
  }
`

const SuggestionsContainer = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40%;
  background: #ffffff;
  font-family: HelveticaNeueLTPro-Roman, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08) !important;
  margin-top: 0.5rem !important;
  z-index: 2;

  @media only screen and (max-width: 990px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
`

const Tooltip = styled.span`
  display: block;
  margin: 0 auto;
  color: #f05d5e;
  text-align: center;
  padding-bottom: 0.5rem;
`

const PostalCode = props => {
  const [text, setText] = useState('')
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    renderSuggestions()
  }, [suggestions])

  const onTextChange = e => {
    const {items} = props
    const value = e.target.value.trim()
    const inputLength = value.length

    let suggestions = []

    if (value.length > 0) {
      suggestions = items.filter(
        sug => sug.Postcode.slice(0, inputLength) === value,
      )
    }

    setSuggestions(suggestions)
    setText(value)
  }

  const suggestionsSelected = value => {
    setText(value.Postcode + ', ' + value.Plaatsnaam)
    setSuggestions([])

    props.handleParentData({
      zipcode: value.Postcode,
      placeName: value.Plaatsnaam,
      province: value.Provincie,
      salesman: value.vertegenwoordiger,
      region: value.Gewest,
      consumerRate: value.Prosumententarief2019,
    })

    props.history.push('/type-customer')
  }

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null
    }

    return (
      <SuggestionsContainer>
        <SuggestionsList>
          {suggestions.slice(0, 4).map((item, index) => (
            <SuggestionsListItem
              key={index}
              onClick={() => suggestionsSelected(item)}
            >
              {item.Postcode}, {item.Plaatsnaam}
            </SuggestionsListItem>
          ))}
        </SuggestionsList>
      </SuggestionsContainer>
    )
  }

  let bannerAlert

  if (props.location.state) {
    const {reloadStepAlert, welcomeAlert} = props.location.state

    if (reloadStepAlert) {
      bannerAlert = (
        <Row>
          <Col md={12}>
            <Alert style={{marginTop: '3em'}} variant='warning'>
              Oeps, er is iets misgegaan met uw verzoek. Probeer het opnieuw.
            </Alert>
          </Col>
        </Row>
      )
    } else if (welcomeAlert) {
      bannerAlert = (
        <Row>
          <Col md={12}>
            <Alert style={{marginTop: '3em'}} variant='warning'>
              <h5>Ontdek uw prijs in slechts enkele seconden</h5>
              Door volgende vragen te beantwoorden ontvangt u een prijs volgens
              uw elektriciteitsverbruik.
            </Alert>
          </Col>
        </Row>
      )
    }
  }

  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <SubTitle>
              Beantwoord deze korte vragen om uw prijs te berekenen
            </SubTitle>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='1rem' marginTop='1rem'>
              Wat is uw postcode?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tooltip
              style={{
                visibility: suggestions.length > 0 ? 'visible' : 'hidden',
              }}
            >
              Kies uw postcode uit de lijst
            </Tooltip>
            <Input
              value={text}
              onChange={onTextChange}
              type='number'
              placeholder='Uw postcode'
            />
            {renderSuggestions()}
          </Col>
        </Row>
        {bannerAlert}
      </Section>
    </Container>
  )
}

export default withRouter(PostalCode)
