import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'
import Navigation from '../../Navigation/Navigation'

const ageBuilding = props => {
  let building = null
  if (props.dataInput.typeCustomer === 'Particulier') {
    building = 'woning'
  } else {
    building = 'gebouw'
  }

  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Hoe oud is uw {building}?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CardInput
              faIcon=''
              type='radio'
              value='Minder dan 10 jaar oud'
              name='ageBuilding'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.ageBuilding === 'Minder dan 10 jaar oud'}
            >
              MINDER DAN <br /> 10 JAAR OUD
            </CardInput>
          </Col>
          <Col md={6}>
            <CardInput
              faIcon=''
              type='radio'
              value='Meer dan 10 jaar oud'
              name='ageBuilding'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.ageBuilding === 'Meer dan 10 jaar oud'}
            >
              MEER DAN <br /> 10 JAAR OUD
            </CardInput>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default ageBuilding
