import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Select from 'react-select'

import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {InputWrapper} from '../../UI/Input/Input'
import {Section} from '../../UI/Section/Section'

const LiberalProfession = props => {
  const options = [
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Accountant',
      label: 'Accountant',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Advocaat',
      label: 'Advocaat',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Apotheker',
      label: 'Apotheker',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Architect',
      label: 'Architect',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Bedrijfsrevisor',
      label: 'Bedrijfsrevisor',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Boekhouder',
      label: 'Boekhouder',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Deurwaarder',
      label: 'Deurwaarder',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Dokter',
      label: 'Dokter',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Supermarkt',
      label: 'Supermarkt',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Fiscaal Adviseur',
      label: 'Fiscaal Adviseur',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Fiscalist',
      label: 'Fiscalist',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Notaris',
      label: 'Notaris',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Opticien',
      label: 'Opticien',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Tandarts',
      label: 'Tandarts',
    },
    {
      name: 'profession',
      optionName: 'professionOption',
      value: 'Andere',
      label: 'Andere',
    },
  ]

  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is uw activiteit?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputWrapper>
              <Select
                value={props.dataInput.professionOption}
                onChange={props.handleChangeSelect}
                options={options}
                isSearchable={false}
              />
            </InputWrapper>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default LiberalProfession
