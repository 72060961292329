import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {Input, InputWrapper} from '../../UI/Input/Input'
import styled from 'styled-components'
import {Section} from '../../UI/Section/Section'

const InputValue = styled(Input)`
  width: 100%;
`

const InputTitleLabel = styled.div`
  position: absolute;
  border: 1px solid #dfe1e5;
  background-color: white;
  top: 0;
  right: 0;
  padding: 0 1em;
  line-height: 2.9;
  font-size: 1.2rem;
  border-radius: 0 5px 5px 0;
`

export const EstimatedKWhConsumption = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Geef uw elektriciteitsverbruik in (kWh)
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputWrapper>
              <InputValue
                type='text'
                name='estimatedKWhConsumption'
                value={props.dataInput.estimatedKWhConsumption}
                onChange={props.onChangeHandler}
              />
              <InputTitleLabel>kWh</InputTitleLabel>
            </InputWrapper>
          </Col>
        </Row>
        <Navigation
            dataInput={props.dataInput}
            onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default EstimatedKWhConsumption
