import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {LinkButton} from '../../UI/LinkButton/LinkButton'
import {Input, InputWrapper} from '../../UI/Input/Input'
import styled from 'styled-components'
import {Section} from '../../UI/Section/Section'

const InputValue = styled(Input)`
  width: 100%;
  margin-bottom: 10px;

  @media only screen and (max-width: 990px) {
    margin-bottom: 20px !important;
  }
`

const InputTitleLabel = styled.div`
  position: absolute;
  border: 1px solid #dfe1e5;
  background-color: white;
  top: 0;
  right: 0;
  padding: 0 1em;
  line-height: 2.9;
  font-size: 1.2rem;
  border-radius: 0 5px 5px 0;
`

const FormErrBgOpen = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  background-color: #f66;
  color: #fff;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 0.5rem !important;
  z-index: 2;
  top: 6rem;
`

const FormErrBg = styled.div`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const FormErrElm = styled.div`
  padding: 10px 20px;
`

const inverterPower = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is het vermogen van uw PV-installatie?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {props.dataInput.inverterPower > 10 ? (
              <FormErrBgOpen>
                <FormErrBg>
                  <FormErrElm>
                    Gelieve een waarde tussen 0.1 en 10 te gebruiken.
                  </FormErrElm>
                </FormErrBg>
              </FormErrBgOpen>
            ) : null}
            <InputWrapper>
              <InputValue
                type='number'
                name='inverterPower'
                value={props.dataInput.inverterPower}
                onChange={props.onChangeHandler}
              />
              <InputTitleLabel>kVA</InputTitleLabel>
            </InputWrapper>
            <Col style={{textAlign: 'center'}} md={12}>
              <LinkButton onClick={props.defaultValueSolarPower}>
                Ik weet het niet. Neem het gemiddelde
              </LinkButton>
            </Col>
          </Col>
        </Row>
        <Navigation
            dataInput={props.dataInput}
            onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default inverterPower
