import React, {Component} from 'react'
import Skeleton from 'react-loading-skeleton'
import {Row, Col, Container, Modal, Spinner} from 'react-bootstrap'
import {Link} from 'react-scroll'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import Button from '../../UI/Button/Button'
import {
  HeroTitle,
  Title,
  SubTitle,
  BoldTitle,
  SecondaryTitle,
} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'
import {MobileMargin60} from '../../UI/Margin/Margin'
import {LinkButton} from '../../UI/LinkButton/LinkButton'
import {Image} from '../../UI/Image/Image'
import {List, ListLine, DivLinebreak} from '../../UI/List/List'
import CheckoutForm from '../../../containers/CheckOut/CheckOut'

import {
  NumberFormat,
  NumberFormatWithOutAbs,
} from '../../../services/numberFormat'

import PSImg from '../../../assets/images/ps-3.jpg'
import PSImgWithOutBattery from '../../../assets/images/ps-without-battery.jpg'
import happyMapImg from '../../../assets/images/icon-happy-map.svg'
import storeEnergyImg from '../../../assets/images/store-energy.svg'
import psSingleImg from '../../../assets/images/ps_single-1.jpg'
import battSingelImg from '../../../assets/images/batt_single-1.jpg'
import {ContentContainer, FullRow, TableContainer, ValueAmount} from './Styled'

const apiUrl = 'https://eluapi.com'

class Reservation extends Component {
  state = {
    resultValues: {
      estimatedElectricalConsumption: 0,
      coverageByPS: 0,
      fromTheGrid: 0,
      suggestedNumberOfPS: null,
      increasedInvestmentAllowance: 0,
      totalPsPrice: 0,
      flemishInvestmentWKK: 0,
      gasConsumption: [],
      serviceContract: [],
      waterConsumption: [],
      totalCostsViaGrid: [],
      restOffTheGrid: [],
      benefitOfHeat: [],
      monthlyAmountGrid: [],
      annualSavings: [],
      extraCapacityGrid: [],
      annualSavingsFinancing: [],
      monthlyPriceElugie: [],
      AvgMonthlyPriceElugie: 0,
      monthlyPriceElugieFinancing: [],
      AvgMonthlyPriceElugieFinancing: 0,
      co2Emissions: 0,
      totalPrice: 0,
      cumulativeAnnualSavings: [],
      cumulativeAnnualSavingsFinancing: [],
      totalEstimatedPlacementCost: 0,
      batterySubsidy: 0,
      totalPsPriceAfterBenefits: 0,
      batteryPrice: 0,
      monthlyFinancing: 0,
      AvgmonthlyFinancing: 0,
      FinancingDuration: 0,
      FinancingInterest: 0,
      typeCustomer: '',
      includeBattery: false,
      benefitOfStorage: [],
      isExistingCustomer: false,
      products: [],
    },
    modal: {
      selfPowerModal: false,
      avgMonthlyPrice: false,
      cumulativePrice: false,
      withElugieModal: false,
      showFinancingDetails: false,
      showCashDetails: false,
    },
    loading: false,
    processing: false,
  }

  // input or radio
  onChangeHandler = e => {
    const {name, value} = e.target

    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [name]: value,
      },
    })
  }

  handleShow = e => {
    const {name} = e.target

    this.setState(prevState => {
      return {
        modal: {
          ...this.state.modal,
          [name]: !prevState.modal[name],
        },
      }
    })
  }

  handleClose = modalName => {
    this.setState({
      modal: {
        ...this.state.modal,
        [modalName]: false,
      },
    })
  }

  componentDidMount() {
    this.setState({loading: true})
    let {simulationId} = this.props.match.params

    if (!simulationId) {
      this.props.history.push(`/zipcode`)
    } else {
      axios
        .get(`${apiUrl}/v2/simulation/${simulationId}`)
        .then(response => {
          const {
            amount_kwh_usage,
            avg_monthly_price_elugie_financing,
            avg_monthly_price_elugie,
            avg_monthly_price_financing,
            financing_duration,
            financing_interest,
            type_customer,
            type_financing,
            total_price,
            include_battery,
            is_existing_customer,
            products,
            total_price_after_benefits,
            created_at,
            settings,
          } = response.data

          const {
            suggested_number_of_ps,
            extra_capacity_grid,
            coverage_by_ps,
            from_the_grid,
            co2_emissions,
            total_estimated_placement_cost,
            flemish_investment_wkk,
            battery_subsidy,
            annual_financing,
            cumulative_annual_savings,
            cumulative_annual_savings_financing,
            monthly_price_elugie_financing,
            total_ps_price,
            increased_investment_allowance,
            amount_kwh_cost_grid,
            total_costs_grid,
            monthly_price_elugie,
            gas_consumption,
            service_contract,
            water_consumption,
            benefit_of_heat,
            annual_savings,
            annual_savings_financing,
            battery_price,
            vat_installation,
            vat_energy,
          } = response.data['results']

          this.setState({
            resultValues: {
              ...this.state.resultValues,
              estimatedElectricalConsumption: amount_kwh_usage * 1,
              suggestedNumberOfPS: suggested_number_of_ps * 1,
              coverageByPS: coverage_by_ps * 1,
              fromTheGrid: from_the_grid * 1,
              co2Emissions: co2_emissions * 1,
              totalEstimatedPlacementCost: total_estimated_placement_cost * 1,
              flemishInvestmentWKK: flemish_investment_wkk * 1,
              batterySubsidy: battery_subsidy * 1,
              batteryPrice: battery_price * 1,
              monthlyFinancing: annual_financing,
              cumulativeAnnualSavings: cumulative_annual_savings,
              cumulativeAnnualSavingsFinancing: cumulative_annual_savings_financing,
              totalPsPrice: total_ps_price * 1,
              totalPrice: total_price * 1,
              totalPsPriceAfterBenefits: total_price_after_benefits * 1,
              increasedInvestmentAllowance: increased_investment_allowance * 1,
              totalCostsViaGrid: amount_kwh_cost_grid,
              restOffTheGrid: total_costs_grid,
              monthlyPriceElugie: monthly_price_elugie,
              AvgMonthlyPriceElugie: avg_monthly_price_elugie,
              avgMonthlyPriceFinancing: avg_monthly_price_financing,
              monthlyPriceElugieFinancing: monthly_price_elugie_financing,
              AvgMonthlyPriceElugieFinancing: avg_monthly_price_elugie_financing,
              gasConsumption: gas_consumption,
              extraCapacityGrid: extra_capacity_grid,
              serviceContract: service_contract,
              waterConsumption: water_consumption,
              benefitOfHeat: benefit_of_heat,
              annualSavings: annual_savings,
              annualSavingsFinancing: annual_savings_financing,
              financingDuration: financing_duration * 1,
              FinancingInterest: financing_interest * 1,
              typeCustomer: type_customer,
              typeFinancing: type_financing,
              includeBattery: include_battery,
              vatInstallation: vat_installation,
              vatEnergy: vat_energy,
              isExistingCustomer: is_existing_customer,
              products: products,
              settings: settings,
              createdAt: created_at,
            },
          })

          this.setState({loading: false})
        })
        .catch(e => {
          if (!e.response) {
            this.setState({
              loading: false,
              error: 'Something went wrong',
            })
          } else {
            this.setState({
              loading: false,
              error: e.response.data.error,
            })
          }
          Cookies.remove('elugie_sim', {path: ''})
          Sentry.withScope(() => {
            Sentry.captureException(e)
          })
          this.props.history.push(`/`)
        })
    }
  }

  downloadPDF = e => {
    const {simulationId} = this.props.match.params
    const {name} = e.target

    if (name === 'downloadAflossingstabel') {
      axios({
        url: `${apiUrl}/v2/simulation/${simulationId}/aflossingstabel`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `aflossingstabel_${simulationId}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
    } else if (name === 'downloadJaarTabel') {
      axios({
        url: `${apiUrl}/v2/simulation/${simulationId}/yeartable`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `jaartabel_${simulationId}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Section textAlign='center'>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </Section>
      )
    }

    if (this.state.error || this.state.resultValues.suggestedNumberOfPS === 0) {
      return (
        <Section>
          <HeroTitle>
            {this.state.error ? this.state.error : 'Te laag verbruik!'}
          </HeroTitle>
        </Section>
      )
    }

    const {
      suggestedNumberOfPS,
      totalPsPrice,
      co2Emissions,
      fromTheGrid,
      coverageByPS,
      estimatedElectricalConsumption,
      totalEstimatedPlacementCost,
      flemishInvestmentWKK,
      batterySubsidy,
      monthlyFinancing,
      cumulativeAnnualSavings,
      increasedInvestmentAllowance,
      totalCostsViaGrid,
      // monthlyPriceElugie,
      // monthlyPriceElugieFinancing,
      gasConsumption,
      extraCapacityGrid,
      serviceContract,
      waterConsumption,
      benefitOfHeat,
      restOffTheGrid,
      annualSavings,
      typeCustomer,
      typeFinancing,
      financingDuration,
      FinancingInterest,
      includeBattery,
      annualSavingsFinancing,
      cumulativeAnnualSavingsFinancing,
      batteryPrice,
      totalPsPriceAfterBenefits,
      products,
      totalPrice,
      AvgMonthlyPriceElugie,
      AvgMonthlyPriceElugieFinancing,
      vatInstallation,
      avgMonthlyPriceFinancing,
      settings,
      vatEnergy,
      // isExistingCustomer,
    } = this.state.resultValues

    const vatIn =
      typeCustomer !== 'Particulier'
        ? '(excl. BTW)'
        : `(incl. ${`${vatInstallation * 100}%`} BTW)`

    const storage = products.filter(
      product => product.product_category === 'Battery',
    )[0]

    const includePs =
      products.filter(product => product.product_category === 'Power Station')
        .length > 0

    const storagePower = includeBattery
      ? storage.product_description.split(' | ')[2]
      : 'Geen'

    const years = [4, 9, 14, 19]

    return (
      <>
        <Modal
          onHide={() => this.handleClose('kWhModal')}
          show={this.state.modal.kWhModal}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Title align='center'>Hoe maken wij deze berekening?</Title>
            <p>
              1 Elugie-centrale kan jaarlijks tot 13.000 kWh produceren. Uw
              verbruik wordt gedeeld door 13.000 kWh en zo wordt het aantal
              centrales bepaald. Bij een verbruik van minder dan 10.000kWh/jaar
              is het financieel niet interessant om een centrale te plaatsen.
            </p>
          </Modal.Body>
        </Modal>
        <Modal
          onHide={() => this.handleClose('avgMonthlyPrice')}
          show={this.state.modal.avgMonthlyPrice}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Title align='center'>
              Hoe werd uw gemiddelde prijs per maand berekend?
            </Title>
            <p>
              De gemiddelde kostprijs per maand is de som van het aandeel
              elektriciteit dat u nog van het net afneemt + alle kosten om zelf
              stroom te maken inclusief de financiering van de Elugie centrale
              en dit over een periode van 20 jaar.
            </p>
          </Modal.Body>
        </Modal>
        <Modal
          onHide={() => this.handleClose('selfPowerModal')}
          show={this.state.modal.selfPowerModal}
          aria-labelledby='contained-modal-title-vcenter'
          size='lg'
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Title align='center'>
              Hoe werkt onafhankelijkheid bij Elugie?
            </Title>
            <p>
              In uw simulatie wordt{' '}
              <strong>{parseFloat(coverageByPS).toFixed(0)}% </strong>
              elektriciteit door uw Elugie-centrale(s) geproduceerd en wordt er
              nog <strong>{parseFloat(fromTheGrid).toFixed(0)}% </strong>
              elektriciteit van het net genomen. Eén centrale kan 13.000 kWh per
              jaar produceren en een extra centrale is financieel minder
              interessant ten opzichte van de kost van de resterende
              elektriciteit van het net te nemen. Om 100% dekking te voorzien
              kan u uiteraard kiezen om toch een extra centrale te plaatsen.
              Dankzij een uitbreiding met het energiemanagementsysteem met
              batterijopslag is er noodstroom voorzien en bent u volledig klaar
              voor de integratie in het Smart Grid van morgen, u gaat dan voor
              volledige energievrijheid.
            </p>
            <Title align='center'>
              Elugie: uw weg naar volledige energievrijheid
            </Title>
            <p>
              De elektriciteitscentrale van Elugie produceert continu 1,5 kW
              elektriciteit dankzij duurzame energie opgeslagen in en
              getransporteerd via het bestaande gasnet. Niet-gebruikte
              elektriciteit gaat het net op en een tekort wordt uit het net
              gehaald. Door uit te breiden met de Elugie-batterij kan u de
              teveel geproduceerde elektriciteit stockeren om 100%
              netonafhankelijk te worden. Wie dagelijks exact kan produceren wat
              er verbruikt wordt, onafhankelijk van beschikbare zonne- of
              windenergie, zal de grote winnaar in de energietransitie zijn.
              Energievrijheid met Elugie staat voor onafhankelijk en maximale
              duurzaamheid aan een lage en stabiele prijs.
            </p>

            <Image alt='elugie-store-energy' src={storeEnergyImg} />
            <br />
            <br />
            <ol>
              <li>
                Continue productie van elektriciteit via de altijd beschikbare
                waterstof opgeslagen in en getransporteerd via het bestaande
                gasnet.
              </li>
              <li>
                Uw dagelijks verbruik is niet constant maar volgt een bepaald
                afnameprofiel.
              </li>
              <li>
                In periodes dat er minder verbruik is dan productie kan energie
                opgeslagen worden voor later gebruik.
              </li>
              <li>
                In periodes van piekverbruik kan opgeslagen energie aangewend
                worden, zo wordt maximale energievrijheid bereikt.
              </li>
            </ol>
          </Modal.Body>
        </Modal>
        <Modal
          onHide={() => this.handleClose('CO2Modal')}
          show={this.state.modal.CO2Modal}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Title align='center'>Hoe werkt duurzaamheid bij Elugie?</Title>
            <p>
              De waterstoftechnologie van Elugie werkt op een zeer hoge
              temperatuur (750°C) wat toelaat om zonder toevoeging van energie
              waterstof uit gas te halen. Er vindt geen verbranding plaats, de
              stroomopwekking gebeurt door een chemische reactie tussen zuurstof
              en waterstof waarbij het hoogste elektrische rendement wereldwijd
              behaald wordt. Bij gebruik van aardgas (fossiel gas) wordt een
              CO2-reductie van 20 tot 50% bereikt ten opzichte van elektriciteit
              uit het stroomnet. Door gebruik te maken van groengas met garantie
              van oorsprong (afkomstig van projecten met zonne- of windenergie,
              biomethaan van landbouwafval of syngas uit biomassa) wordt uw
              CO2-uitstoot bijna volledig tot 0 gereduceerd. Opslag van alle
              hernieuwbare energie in gasvorm heeft het ongekende voordeel dat
              op deze manier duurzame energie duizenden keren gemakkelijker en
              goedkoper kan getransporteerd worden dan energie via het
              elektriciteitsnet. De weg naar 100% duurzaam voor elektriciteit
              kan via Elugie vandaag al bewandeld worden. Bij ons geen
              combinatie van groene wind- of zonne-energie met alsnog
              elektriciteit van nucleaire, steenkool- of gascentrales in de
              winter. Neen, wij gaan voor duurzame stroom, op elke seconde van
              de dag, aangepast aan ons verbruiksprofiel. U toch ook?
            </p>

            <Title align='center'>Hoe werd uw CO2-besparing berekend?</Title>
            <p>
              Met aardgas kan u vandaag tot 20% CO2 besparen met Elugie (na de
              kernuitstap tot 50%) en met groengas vandaag al tot 97% t.o.v.
              elektriciteit uit het stroomnet.
            </p>
          </Modal.Body>
        </Modal>

        {includePs ? (
          <Section paddingTop='60px' paddingBottom='120px'>
            <Container>
              <Row>
                <Col md={12}>
                  <ContentContainer>
                    <HeroTitle>
                      U kan {suggestedNumberOfPS} Elugie-centrale(s) inzetten
                    </HeroTitle>
                    <SubTitle>Gebaseerd op uw energieverbruik van:</SubTitle>
                    <BoldTitle>
                      {parseFloat(estimatedElectricalConsumption).toFixed(0)}{' '}
                      kWh / jaar
                    </BoldTitle>
                    <LinkButton name='kWhModal' onClick={this.handleShow}>
                      Meer weten
                    </LinkButton>
                  </ContentContainer>
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col md={12}>
                  <Image
                    alt='elugie-power-station'
                    src={
                      this.state.resultValues.includeBattery
                        ? PSImg
                        : PSImgWithOutBattery
                    }
                    width='25%'
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <MobileMargin60>
                    <TableContainer>
                      <Row>
                        <Col md={6}>
                          <ValueAmount fontSize='40px'>
                            {parseFloat(coverageByPS).toFixed(0)}%
                          </ValueAmount>
                          <SubTitle>Zelfstroom</SubTitle>
                        </Col>
                        <Col md={6} className='border-left'>
                          <ValueAmount fontSize='40px' opacity='0.6'>
                            {parseFloat(fromTheGrid).toFixed(0)}%
                          </ValueAmount>
                          <SubTitle opacity='0.6'>Van het net</SubTitle>
                        </Col>
                      </Row>
                      <SubTitle>
                        Volledige onafhankelijk van de stijgende stroomprijzen
                      </SubTitle>
                      <LinkButton
                        name='selfPowerModal'
                        onClick={this.handleShow}
                      >
                        Meer weten
                      </LinkButton>
                    </TableContainer>
                  </MobileMargin60>
                </Col>
                <Col md={4} className='border-left'>
                  <MobileMargin60>
                    <TableContainer>
                      <ValueAmount fontSize='40px'>
                        <NumberFormat>
                          {typeFinancing !== 'Eigen middelen'
                            ? AvgMonthlyPriceElugieFinancing
                            : AvgMonthlyPriceElugie}
                        </NumberFormat>
                      </ValueAmount>
                      <SubTitle>Gemiddelde prijs per maand</SubTitle>
                      <SubTitle>
                        Rekening houdend met alle kosten over 20 jaar
                      </SubTitle>
                      <LinkButton
                        name='avgMonthlyPrice'
                        onClick={this.handleShow}
                      >
                        Meer weten
                      </LinkButton>
                    </TableContainer>
                  </MobileMargin60>
                </Col>
                <Col md={4} className='border-left'>
                  <MobileMargin60>
                    <TableContainer>
                      <ValueAmount fontSize='40px'>{co2Emissions}%</ValueAmount>
                      <SubTitle>Minder CO₂ uitstoot</SubTitle>
                      <SubTitle>
                        Gebruik enkel groengas en waterstof uit duurzame bronnen
                      </SubTitle>
                      <LinkButton name='CO2Modal' onClick={this.handleShow}>
                        Meer weten
                      </LinkButton>
                    </TableContainer>
                  </MobileMargin60>
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}
        {includePs ? (
          <Section paddingTop='0px' paddingBottom='0px'>
            <FullRow>
              <Container>
                <Row>
                  <Col md={12}>
                    <ContentContainer>
                      <Title align='center'>
                        Start samen met ons de waterstofrevolutie
                      </Title>
                    </ContentContainer>
                  </Col>
                  <Col md={3}>
                    <Image alt='happy-map' src={happyMapImg} margin='none' />
                  </Col>
                  <Col md={3}>
                    <div>
                      <SubTitle fontSize='16px' opacity='0.5'>
                        ELUGIE-CENTRALES
                      </SubTitle>
                      <ValueAmount>450+</ValueAmount>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <SubTitle fontSize='16px' opacity='0.5'>
                        HUIDIGE CAPACITEIT IN BELGIË
                      </SubTitle>
                      <ValueAmount>
                        675<sub>kW</sub>
                      </ValueAmount>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <SubTitle fontSize='16px' opacity='0.5'>
                        CO2 BESPAARD
                      </SubTitle>
                      <ValueAmount>
                        4.920<sub>ton</sub>
                      </ValueAmount>
                    </div>
                  </Col>
                </Row>
              </Container>
            </FullRow>
          </Section>
        ) : null}
        <Section paddingTop='120px' paddingBottom='120px'>
          <Container>
            <Row>
              <Col md={12}>
                <ContentContainer>
                  <Title align='center'>Eigenschappen</Title>
                  <SubTitle>
                    {includePs
                      ? 'Technische gegevens van productie en opslag'
                      : 'Technische gegevens van opslag'}
                  </SubTitle>
                </ContentContainer>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                style={{
                  opacity: includePs ? 1 : 0.3,
                }}
              >
                <div style={{padding: '0 16px'}}>
                  <TableContainer>
                    <Image
                      alt='elugie-power-station'
                      width='200px'
                      margin='0 0 14px 0'
                      display='inline-block'
                      src={psSingleImg}
                    />
                  </TableContainer>
                </div>
                <div>
                  <TableContainer>
                    {includePs ? (
                      <SecondaryTitle>
                        {suggestedNumberOfPS} x Elugie-centrale(s)
                      </SecondaryTitle>
                    ) : null}
                    {includePs ? (
                      <List>
                        <ListLine>
                          <BoldTitle align='left'>Productie</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {1.5 * suggestedNumberOfPS} kW -{' '}
                            {13000 * suggestedNumberOfPS} kWh elektriciteit /
                            jaar
                          </span>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {0.75 * suggestedNumberOfPS} kW -{' '}
                            {6500 * suggestedNumberOfPS} kWh warmte / jaar
                          </span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>Verbruik</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {22000 * suggestedNumberOfPS} kWh verbruik gas of
                            groengas / jaar
                          </span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>Technologie</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>- Waterstoftechnologie (SOFC)</span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>
                            Installatie (per centrale)
                          </BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>- 120cm(h), 55cm(b), 80cm(l)</span>
                        </ListLine>
                        <ListLine>
                          <span>- 250 kg</span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>
                            Service en garantie:
                          </BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>- 10 tot 20 jaar</span>
                        </ListLine>
                      </List>
                    ) : (
                      <List>
                        <Skeleton duration={0} width={300} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={300} />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={230} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={210} />
                        <Skeleton duration={0} width={230} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={270} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={230} />
                        <Skeleton duration={0} width={270} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={270} />
                        <Skeleton duration={0} width={270} />
                      </List>
                    )}
                  </TableContainer>
                </div>
              </Col>
              <Col
                style={{
                  opacity: this.state.resultValues.includeBattery ? 1 : 0.3,
                }}
                md={6}
              >
                <div>
                  <TableContainer>
                    <Image
                      alt='elugie-power-station'
                      width='200px'
                      margin='0 0 14px 0'
                      display='inline-block'
                      src={battSingelImg}
                    />
                  </TableContainer>
                </div>
                <div className='border-left'>
                  <TableContainer>
                    <SecondaryTitle>{storagePower} opslag</SecondaryTitle>
                    {includeBattery ? (
                      <List>
                        <ListLine>
                          <BoldTitle align='left'>Netaansluiting</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>
                            - Nominal power{' '}
                            {storage.product_tech_description.effective_power}kW
                          </span>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {storage.product_tech_description.mains_voltage}{' '}
                            {storage.product_tech_description.grid_type}
                          </span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>Technologie</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>
                            -{' '}
                            {
                              storage.product_tech_description
                                .battery_technology
                            }
                          </span>
                        </ListLine>
                        <ListLine>
                          <span>
                            -{' '}
                            {
                              storage.product_tech_description
                                .nominal_energy_content
                            }{' '}
                            kWh
                          </span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>Installatie</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>
                            -{' '}
                            {
                              storage.product_tech_description
                                .installation_location
                            }
                          </span>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {storage.product_tech_description.dimensions}
                          </span>
                        </ListLine>
                        <ListLine>
                          <span>
                            - {storage.product_tech_description.weight} kg
                          </span>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>Garantie</BoldTitle>
                        </ListLine>
                        <ListLine>
                          <span>- Tot 10 jaar garantie</span>
                        </ListLine>
                        <ListLine>
                          <span>- Verwachte levensduur tot 20 jaar</span>
                        </ListLine>
                      </List>
                    ) : (
                      <List>
                        <Skeleton duration={0} width={300} />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={230} />
                        <Skeleton duration={0} width={270} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={210} />
                        <Skeleton duration={0} width={230} />
                        <Skeleton duration={0} width={250} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                        <Skeleton duration={0} width={230} />
                        <br />
                        <br />
                        <Skeleton duration={0} width={200} />
                        <Skeleton duration={0} width={250} />
                      </List>
                    )}
                  </TableContainer>
                </div>
              </Col>
            </Row>
            <Row style={{textAlign: 'center', marginTop: '48px'}}>
              <Col md={12}>
                <Link to='section1' smooth={true} offset={-70}>
                  <Button>
                    RESERVEER MIJN {includePs ? 'ELUGIE CENTRALE' : 'BATTERIJ'}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section paddingTop='0px' paddingBottom='0px' id='pricing'>
          <FullRow>
            <Container>
              <Col md={12}>
                <ContentContainer>
                  {includePs ? (
                    <Title align='center'>Prijsberekening en besparing</Title>
                  ) : (
                    <Title align='center'>Prijsberekening</Title>
                  )}
                  <SubTitle>
                    bij{' '}
                    {typeFinancing === 'Eigen middelen'
                      ? 'Cash aankoop'
                      : typeFinancing}
                  </SubTitle>
                </ContentContainer>
                {typeFinancing === 'Eigen middelen' ? (
                  <div>
                    <List>
                      <>
                        {includePs ? (
                          <ListLine>
                            <span>
                              {suggestedNumberOfPS} x Elugie-centrale(s)
                            </span>
                            <span>
                              <NumberFormat>{totalPsPrice}</NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        {includeBattery ? (
                          <ListLine>
                            <span>1 x {storagePower} opslag</span>
                            <span>
                              <NumberFormat>{batteryPrice}</NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        <ListLine>
                          <span>Plaatsingskost</span>
                          <span>
                            <NumberFormat>
                              {totalEstimatedPlacementCost}
                            </NumberFormat>
                          </span>
                        </ListLine>
                        {includePs ? (
                          <ListLine>
                            <span>
                              Vlaamse subsidies voor waterstof technologie
                            </span>
                            <span>
                              -{' '}
                              <NumberFormat>
                                {flemishInvestmentWKK}
                              </NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        {typeCustomer === 'Particulier' && includeBattery ? (
                          <ListLine>
                            <span>Vlaamse subsidies voor batterijen</span>
                            <span>
                              - <NumberFormat>{batterySubsidy}</NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        {typeCustomer !== 'Particulier' ? (
                          <ListLine>
                            <span>Verhoogde investering aftrek</span>
                            <span>
                              -{' '}
                              <NumberFormat>
                                {increasedInvestmentAllowance}
                              </NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                      </>
                      {!includePs ? (
                        <DivLinebreak paddingBottom='15px' paddingTop='0' />
                      ) : null}
                      <ListLine>
                        <BoldTitle align='left'>
                          Totaal prijs{' '}
                          {typeCustomer !== 'KMO' ? 'na subsidies' : null}{' '}
                          {vatIn}{' '}
                        </BoldTitle>
                        <BoldTitle>
                          <NumberFormat>
                            {totalPsPriceAfterBenefits}
                          </NumberFormat>
                        </BoldTitle>
                      </ListLine>
                    </List>
                  </div>
                ) : (
                  <>
                    <List>
                      <>
                        {includePs ? (
                          <ListLine>
                            <span>
                              {suggestedNumberOfPS} x Elugie-centrale(s)
                            </span>
                            <span>
                              <NumberFormat>{totalPsPrice}</NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        {includeBattery ? (
                          <ListLine>
                            <span>1 x {storagePower} opslag</span>
                            <span>
                              <NumberFormat>{batteryPrice}</NumberFormat>
                            </span>
                          </ListLine>
                        ) : null}
                        <ListLine>
                          <BoldTitle align='left'>
                            Te financieren bedrag
                          </BoldTitle>
                          <BoldTitle align='left'>
                            <NumberFormat>{totalPrice}</NumberFormat>
                          </BoldTitle>
                        </ListLine>
                      </>
                    </List>
                    <DivLinebreak>
                      <List>
                        <>
                          <ListLine>
                            <span>Plaatsingskost</span>
                            <span>
                              <NumberFormat>
                                {totalEstimatedPlacementCost}
                              </NumberFormat>
                            </span>
                          </ListLine>
                          {includePs ? (
                            <ListLine>
                              <span>
                                Vlaamse subsidies voor waterstoftechnologie
                              </span>
                              <span>
                                -{' '}
                                <NumberFormat>
                                  {flemishInvestmentWKK}
                                </NumberFormat>
                              </span>
                            </ListLine>
                          ) : null}
                          {typeCustomer === 'Particulier' && includeBattery ? (
                            <ListLine>
                              <span>Vlaamse subsidies voor batterijen</span>
                              <span>
                                - <NumberFormat>{batterySubsidy}</NumberFormat>
                              </span>
                            </ListLine>
                          ) : null}
                        </>

                        <ListLine>
                          <BoldTitle align='left'>
                            Eigen inbreng plaatsingskost na subsidies (excl.BTW)
                          </BoldTitle>
                          <BoldTitle align='left'>
                            <NumberFormatWithOutAbs>
                              {totalEstimatedPlacementCost +
                                flemishInvestmentWKK +
                                batterySubsidy}
                            </NumberFormatWithOutAbs>
                          </BoldTitle>
                        </ListLine>
                      </List>
                    </DivLinebreak>
                    <DivLinebreak>
                      <List>
                        <ListLine>
                          {includePs ? (
                            <BoldTitle align='left'>
                              Uw prijs per maand voor {suggestedNumberOfPS}{' '}
                              Elugie-centrale(s){' '}
                              {includeBattery
                                ? `en ${storagePower} opslag`
                                : null}
                            </BoldTitle>
                          ) : (
                            <BoldTitle align='left'>
                              Uw prijs per maand
                            </BoldTitle>
                          )}
                          <BoldTitle align='left'>
                            <NumberFormat>
                              {avgMonthlyPriceFinancing}
                            </NumberFormat>
                          </BoldTitle>
                        </ListLine>
                        <ListLine>
                          <SubTitle opacity='0.5' marginBottom='0'>
                            {financingDuration} maanden, {FinancingInterest}%{' '}
                            {vatIn}
                            {typeFinancing === 'Operational renting'
                              ? ', 16% afkoopwaarde'
                              : null}
                          </SubTitle>
                        </ListLine>
                        <ListLine>
                          <BoldTitle align='left'>
                            <LinkButton
                              margin='none'
                              name='downloadAflossingstabel'
                              onClick={this.downloadPDF}
                            >
                              Download aflossingstabel
                            </LinkButton>
                          </BoldTitle>
                        </ListLine>
                      </List>
                    </DivLinebreak>
                  </>
                )}
                {includePs ? (
                  <DivLinebreak>
                    <List>
                      <ListLine>
                        <BoldTitle align='left'>
                          Uw bruto besparing op 20 jaar met Elugie
                        </BoldTitle>
                        <BoldTitle align='left'>
                          <NumberFormat>
                            {typeFinancing !== 'Eigen middelen'
                              ? cumulativeAnnualSavingsFinancing[19]
                              : cumulativeAnnualSavings[19]}
                          </NumberFormat>
                        </BoldTitle>
                      </ListLine>
                    </List>
                  </DivLinebreak>
                ) : null}
                {includePs ? (
                  <div>
                    <SubTitle opacity='0.5'>
                      De Vlaamse steun ontvangt u na opstart en aanvraag van uw
                      subsidie.{' '}
                      {typeFinancing === 'Eigen middelen'
                        ? ' Het verhoogd fiscaal voordeel is het netto voordeel dat u geniet voor het desbetreffende fiscale jaar, enkel voor KMO’s'
                        : null}{' '}
                      De plaatsingskost voor {suggestedNumberOfPS} centrale(s)
                      is afhankelijk van uw situatie. Na een bezoek van een
                      lokale Elugie installatiepartner bij u ter plaatse kan een
                      prijs op maat van uw situatie worden opgemaakt.
                    </SubTitle>
                  </div>
                ) : null}
              </Col>
            </Container>
          </FullRow>
        </Section>
        {includePs ? (
          <Section paddingTop='120px' paddingBottom='120px'>
            <Container>
              <Row>
                <Col md={12}>
                  <ContentContainer>
                    <Title align='center'>
                      Hoe werd uw besparing berekend?
                    </Title>
                    <SubTitle>
                      Zelfstroom met Elugie t.o.v. elektriciteit uit het
                      stroomnet
                    </SubTitle>
                  </ContentContainer>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{overflowX: 'auto'}}>
                    <table style={{width: '100%', textAlign: 'right'}}>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <BoldTitle align='right'>5de jaar</BoldTitle>
                          </td>
                          <td>
                            <BoldTitle align='right'>10de jaar</BoldTitle>
                          </td>
                          <td>
                            <BoldTitle align='right'>15de jaar</BoldTitle>
                          </td>
                          <td>
                            <BoldTitle align='right'>20ste jaar</BoldTitle>
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>
                            <BoldTitle align='left' opacity='0.6'>
                              Elektriciteit uit het net:
                            </BoldTitle>
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <BoldTitle align='right' opacity='0.6'>
                                  <NumberFormat>
                                    {totalCostsViaGrid[value]}
                                  </NumberFormat>
                                </BoldTitle>
                              </td>
                            )
                          })}
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'left'}}>
                            <BoldTitle align='left'>
                              Zelfstroom met Elugie:
                            </BoldTitle>
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <BoldTitle align='right'>
                                  <NumberFormat>
                                    {gasConsumption[value + 1] * 12 +
                                      restOffTheGrid[value + 1] * 12 +
                                      serviceContract[value + 1] * 12 +
                                      waterConsumption[value + 1] * 12}
                                  </NumberFormat>
                                </BoldTitle>
                              </td>
                            )
                          })}
                        </tr>
                        <tr style={{fontSize: '15px'}}>
                          <td style={{textAlign: 'left'}}>Gasgebruik</td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <NumberFormatWithOutAbs>
                                  {gasConsumption[value + 1] * 12}
                                </NumberFormatWithOutAbs>
                              </td>
                            )
                          })}
                        </tr>
                        <tr style={{fontSize: '15px'}}>
                          <td style={{textAlign: 'left'}}>
                            Restafname van het net
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <NumberFormatWithOutAbs>
                                  {restOffTheGrid[value + 1] * 12}
                                </NumberFormatWithOutAbs>
                              </td>
                            )
                          })}
                        </tr>
                        <tr style={{fontSize: '15px'}}>
                          <td style={{textAlign: 'left'}}>Servicecontract</td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <NumberFormatWithOutAbs>
                                  {serviceContract[value + 1] * 12}
                                </NumberFormatWithOutAbs>
                              </td>
                            )
                          })}
                        </tr>
                        <tr style={{fontSize: '15px'}}>
                          <td style={{textAlign: 'left'}}>Waterverbruik</td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <NumberFormatWithOutAbs>
                                  {waterConsumption[value + 1] * 12}
                                </NumberFormatWithOutAbs>
                              </td>
                            )
                          })}
                        </tr>
                        {typeFinancing !== 'Eigen middelen' ? (
                          <>
                            <tr>
                              <td style={{textAlign: 'left'}}>
                                <BoldTitle align='left'>
                                  Financiering:
                                </BoldTitle>
                              </td>
                              {years.map((value, index) => {
                                return (
                                  <td key={index}>
                                    <BoldTitle align='right'>
                                      <NumberFormat>
                                        {monthlyFinancing[value]}
                                      </NumberFormat>
                                    </BoldTitle>
                                  </td>
                                )
                              })}
                            </tr>
                            <tr style={{fontSize: '15px'}}>
                              <td style={{textAlign: 'left'}}>
                                {typeFinancing === 'Operational renting'
                                  ? 'Leasing'
                                  : 'Bankfinanciering'}
                              </td>
                              {years.map((value, index) => {
                                return (
                                  <td key={index}>
                                    <NumberFormatWithOutAbs>
                                      {monthlyFinancing[value]}
                                    </NumberFormatWithOutAbs>
                                  </td>
                                )
                              })}
                            </tr>
                          </>
                        ) : null}
                        <tr>
                          <td style={{textAlign: 'left'}}>
                            <BoldTitle align='left'>Voordelen:</BoldTitle>
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <BoldTitle align='right'>
                                  -{' '}
                                  <NumberFormatWithOutAbs>
                                    {benefitOfHeat[value + 1] * 12 +
                                      extraCapacityGrid[value] * 12}
                                  </NumberFormatWithOutAbs>
                                </BoldTitle>
                              </td>
                            )
                          })}
                        </tr>
                        <tr style={{fontSize: '15px'}}>
                          <td style={{textAlign: 'left'}}>Voordeel warmte</td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                -{' '}
                                <NumberFormatWithOutAbs>
                                  {benefitOfHeat[value + 1] * 12}
                                </NumberFormatWithOutAbs>
                              </td>
                            )
                          })}
                        </tr>
                        {includeBattery ? (
                          <tr style={{fontSize: '15px'}}>
                            <td style={{textAlign: 'left'}}>
                              Besparing op huur noodstroomgenerator (noodstroom
                              alternatief)
                            </td>
                            {years.map((value, index) => {
                              return (
                                <td key={index}>
                                  -{' '}
                                  <NumberFormatWithOutAbs>
                                    {extraCapacityGrid[value] * 12}
                                  </NumberFormatWithOutAbs>
                                </td>
                              )
                            })}
                          </tr>
                        ) : null}
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'left'}}>
                            <BoldTitle align='left' color='#00A385'>
                              Jaarlijks besparing
                            </BoldTitle>
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <BoldTitle align='right' color='#00A385'>
                                  <NumberFormatWithOutAbs>
                                    {typeFinancing !== 'Eigen middelen'
                                      ? annualSavingsFinancing[value]
                                      : annualSavings[value]}
                                  </NumberFormatWithOutAbs>
                                </BoldTitle>
                              </td>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{textAlign: 'left'}}>
                            <BoldTitle align='left' color='#00A385'>
                              Cumul besparing
                            </BoldTitle>
                          </td>
                          {years.map((value, index) => {
                            return (
                              <td key={index}>
                                <BoldTitle align='right' color='#00A385'>
                                  <NumberFormatWithOutAbs>
                                    {typeFinancing !== 'Eigen middelen'
                                      ? cumulativeAnnualSavingsFinancing[value]
                                      : cumulativeAnnualSavings[value]}
                                  </NumberFormatWithOutAbs>
                                </BoldTitle>
                              </td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <div>
                      <SubTitle fontSize='14px' align='left' opacity='0.5'>
                        1. De jaarlijkse kosten voor elektriciteit via het net
                        (incl. netbeheerkosten etc.) stijgen de eerste 10 jaar
                        met {settings['electricity_indexation'] * 100}% en
                        vervolgens met{' '}
                        {settings['electricity_indexation_10'] * 100}% per jaar.
                        De jaarlijkse elektriciteitskosten worden berekend op{' '}
                        {typeCustomer !== 'KMO'
                          ? `€ ` +
                            (settings
                              ? settings['electricity_price'] * (1 + vatEnergy)
                              : null) +
                            ` (incl. BTW)`
                          : `€ ` +
                            (settings ? settings['electricity_price'] : null) +
                            ' excl. BTW'}{' '}
                        per kWh.
                      </SubTitle>
                      <SubTitle fontSize='14px' align='left' opacity='0.5'>
                        2. De gas-kosten om elektriciteit op te wekken met de
                        Elugie-centrale(s) stijgen gemiddeld met 2% per jaar en
                        de jaarlijkse kosten voor het gasverbruik worden
                        berekend op{' '}
                        {typeCustomer !== 'KMO'
                          ? '€ 0,042 (incl. BTW)'
                          : '€ 0,035'}{' '}
                        per kWh.
                      </SubTitle>
                      <SubTitle fontSize='14px' align='left' opacity='0.5'>
                        3. De jaarlijkse kosten voor servicecontract en
                        waterverbruik worden berekend volgens de
                        consumentenindex van 2% per jaar.
                      </SubTitle>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}
        <Section paddingTop='120px' paddingBottom='120px' id='section1'>
          <Container>
            <Row>
              <Col md={12}>
                <div>
                  <Title align='center'>
                    Reserveer vandaag nog voor slechts €100
                  </Title>
                  <SubTitle>
                    Plaatsing van deze nieuwe generatie centrales start in 2021.
                  </SubTitle>
                </div>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={{size: 5, order: 12}}>
                <div>
                  <div>
                    <BoldTitle align='left'>Samenvatting</BoldTitle>
                  </div>
                  <DivLinebreak>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: '20px',
                            marginButton: '20px',
                          }}
                        >
                          {includePs ? (
                            <Image
                              alt='checkout-image'
                              width='35%'
                              src={includeBattery ? PSImg : PSImgWithOutBattery}
                            />
                          ) : (
                            <Image
                              alt='checkout-image'
                              width='35%'
                              src={battSingelImg}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          {includePs ? (
                            <SubTitle>
                              {suggestedNumberOfPS} x Elugie-centrale(s)
                            </SubTitle>
                          ) : null}
                          {includeBattery ? (
                            <SubTitle>{storagePower} opslag</SubTitle>
                          ) : null}
                        </div>
                        <br />
                      </Col>
                      <br />
                      <br />
                      <Col md={12}>
                        <div
                          style={{
                            paddingBottom: '30px',
                          }}
                        >
                          {includePs ? (
                            <Row>
                              <Col xs={6}>
                                <ValueAmount fontSize='40px'>
                                  {parseFloat(coverageByPS).toFixed(0)}%
                                </ValueAmount>
                                <SubTitle>Zelfstroom</SubTitle>
                              </Col>
                              <Col className='border-left' xs={6}>
                                <ValueAmount fontSize='40px'>
                                  {co2Emissions}%
                                </ValueAmount>
                                <SubTitle>Minder CO2 uitstoot </SubTitle>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </DivLinebreak>
                  <DivLinebreak>
                    <Row>
                      <Col md={12}>
                        <div>
                          {typeFinancing !== 'Eigen middelen' ? (
                            <List>
                              <ListLine>
                                <SubTitle>
                                  Eigen inbreng plaatsingskost
                                </SubTitle>
                                <SubTitle>
                                  <NumberFormatWithOutAbs>
                                    {totalEstimatedPlacementCost +
                                      flemishInvestmentWKK +
                                      batterySubsidy}
                                  </NumberFormatWithOutAbs>
                                </SubTitle>
                              </ListLine>
                              <ListLine>
                                <SubTitle>Uw prijs per maand</SubTitle>
                                <SubTitle>
                                  <NumberFormat>
                                    {avgMonthlyPriceFinancing}
                                  </NumberFormat>
                                </SubTitle>
                              </ListLine>
                              {includePs ? (
                                <ListLine>
                                  <SubTitle>Uw besparing op 20 jaar</SubTitle>
                                  <SubTitle>
                                    <NumberFormat>
                                      {cumulativeAnnualSavingsFinancing[19]}
                                    </NumberFormat>
                                  </SubTitle>
                                </ListLine>
                              ) : null}
                              <ListLine>
                                <BoldTitle>Vandaag te voldoen</BoldTitle>
                                <BoldTitle>€ 100</BoldTitle>
                              </ListLine>
                            </List>
                          ) : (
                            <List>
                              <ListLine>
                                <SubTitle>
                                  Totaal prijs na subsidies {vatIn}
                                </SubTitle>
                                <SubTitle>
                                  <NumberFormat>
                                    {totalPsPriceAfterBenefits}
                                  </NumberFormat>
                                </SubTitle>
                              </ListLine>
                              {includePs ? (
                                <ListLine>
                                  <SubTitle>Uw besparing op 20 jaar</SubTitle>
                                  <SubTitle>
                                    <NumberFormat>
                                      {cumulativeAnnualSavings[19]}
                                    </NumberFormat>
                                  </SubTitle>
                                </ListLine>
                              ) : null}
                              <ListLine>
                                <BoldTitle>Vandaag te voldoen</BoldTitle>
                                <BoldTitle>€ 100</BoldTitle>
                              </ListLine>
                            </List>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </DivLinebreak>
                  <div style={{paddingBottom: '30px'}}>
                    <SubTitle align='left' opacity='0.5'>
                      {includePs
                        ? 'U betaalt vandaag €100 en plaatst een vrijblijvende reservatie voor een of meerdere eigen zelfstroomcentrale(s).'
                        : 'U betaalt vandaag €100 en plaatst een vrijblijvende reservatie voor batterijsyteem.'}{' '}
                      De reservatie is vrijblijvend en kan op elk moment door u
                      worden geannuleerd.
                    </SubTitle>
                  </div>
                </div>
              </Col>
              <Col md={{size: 7, order: 1}}>
                <div>
                  <div>
                    <BoldTitle align='left'>Accountgegevens</BoldTitle>
                  </div>
                  <CheckoutForm typeCustomer={typeCustomer} price={10} />
                  <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <SubTitle align='left' opacity='0.5'>
                      De reservatie is onder voorbehoud van goedkeuring van
                      financiering, detail bepaling van uw afname profiel en
                      goedkeuring van de plaatsingskost. Na de betaling ontvangt
                      u van ons een factuur voor het betaalde bedrag. De
                      batterijcapaciteit wordt na reservatie in detail bepaald
                      en gedimensioneerd op maat van uw project aan de hand van
                      de meting die we voorzien van uw afname profiel. De
                      huidige dimensionering is louter informatief.
                    </SubTitle>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    )
  }
}

export default withRouter(Reservation)
