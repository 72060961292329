import styled from 'styled-components'

export const Image = styled.img`
  display: ${({display}) => display || 'block'};
  margin: ${({margin}) => margin || '0 auto'};
  max-width: ${({maxWith}) => maxWith || '100%'};
  width: ${({width}) => width || '100%'};
  height: auto;

  @media only screen and (max-width: 500px) {
    padding-bottom: 30px;
    width: 70%;
  }

  @media only screen and (max-width: 1020px) and (min-width: 500px) {
    width: 40%;
  }
`
