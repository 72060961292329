import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'

const GasConnection = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Heeft u een gasaansluiting?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CardInput
              faIcon=''
              value='Gas ok'
              type='radio'
              name='connectionGas'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.connectionGas === 'Gas ok'}
            >
              JA
            </CardInput>
          </Col>
          <Col md={4}>
            <CardInput
              faIcon=''
              type='radio'
              value='Gas mogelijk'
              name='connectionGas'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.connectionGas === 'Gas mogelijk'}
            >
              NEE, MAAR WEL <br /> AANSLUITBAAR
            </CardInput>
          </Col>
          <Col md={4}>
            <CardInput
              faIcon=''
              type='radio'
              value='Geen gas'
              name='connectionGas'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.connectionGas === 'Geen gas'}
            >
              NEE, NIET MOGELIJK
            </CardInput>
          </Col>
        </Row>
        <Navigation
            dataInput={props.dataInput}
            onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default GasConnection
