import React from 'react'
import styled from 'styled-components'

const Link = styled.button`
  margin: ${({margin}) => margin || 'auto'};
  margin-top: ${({marginTop}) => marginTop || '12px'};
  display: block;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: #01a384;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    color: #00a385;
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline: 0 !important;
  }
`

export const LinkButton = props => (
  <Link
    margin={props.margin}
    name={props.name}
    onClick={props.onClick}
  >
    {props.children}
  </Link>
)