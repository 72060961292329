import React, {Component} from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import RangeInput from '../../UI/RangeInput/RangeInput'
import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import styled from 'styled-components'
import {Section} from '../../UI/Section/Section'

const ContentWrapper = styled.div`
  position: relative;
  padding: 0 16rem;
  max-width: 90rem;
  margin: 0 auto;
  height: 220px;
  width: 100%;

  @media only screen and (max-width: 990px) {
    padding: 0;
  }
`

const RangeLabel = styled.span`
  font-size: 4.5rem;
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: bold;
  display: block;
  text-align: center;
`

const MinMax = styled.div`
  width: auto;
  display: flex;
  color: #a8acc2;
  justify-content: space-between;
  font-size: 15px;
`

const PerMo = styled(MinMax)`
  text-align: center;
  display: block;
  margin-bottom: 10px;
`

class Usage extends Component {
  state = {
    startValue: 400,
    minVal: 100,
    maxVal: 3450,
    stepVal: 50,
  }
  render() {
    return (
      <Container>
        <Section>
          <Row>
            <Col md={12}>
              <Title align='center' marginBottom='2.5rem'>
                Hoeveel betaalt u maandelijks voor uw elektriciteitsverbruik?
              </Title>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ContentWrapper>
                <RangeLabel>
                  €{' '}
                  {this.props.dataInput.currentConsumption === 0
                    ? this.state.startValue
                    : this.props.dataInput.currentConsumption}{' '}
                  {this.props.dataInput.currentConsumption > 3449 ? (
                    <span> + </span>
                  ) : null}{' '}
                </RangeLabel>
                <PerMo>/per maand</PerMo>
                <RangeInput
                  name='currentConsumption'
                  onChange={this.props.onChangeHandler}
                  value={
                    this.props.dataInput.currentConsumption === 0
                      ? this.state.startValue
                      : this.props.dataInput.currentConsumption
                  }
                  min={this.state.minVal}
                  max={this.state.maxVal}
                  step={this.state.stepVal}
                />
                <MinMax>
                  <span>€ {this.state.minVal}</span>
                  <span>€ {this.state.maxVal} +</span>
                </MinMax>
              </ContentWrapper>
            </Col>
          </Row>
          <Navigation
            dataInput={this.props.dataInput}
            onClick={this.props.onClickHandler}
          />
        </Section>
      </Container>
    )
  }
}

export default Usage
