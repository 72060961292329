import React, {Fragment, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'

import ZipCodeData from '../../services/zipcodeApi'
import {titleCaseURL} from '../../helpers'
import Button from '../UI/Button/Button'
import {LinkButton} from '../UI/LinkButton/LinkButton'

const Navigation = props => {
  const goBack = () => {
    props.history.goBack()
  }

  // If field empty then disable the button
  const locationPath = props.history.location.pathname
  const pathName = locationPath
    .slice(1, locationPath.length)
    .split('-')
    .join(' ')

  const zipCodeValidation = ZipCodeData.some(
    sug =>
      sug.Postcode === props.dataInput.zipcode &&
      sug.Plaatsnaam === props.dataInput.placeName,
  )

  let buttonEnable = false

  // Check if the state state is empty
  if (locationPath === '/zipcode' && zipCodeValidation) {
    buttonEnable = !buttonEnable
  } else if (
    props.dataInput[titleCaseURL(pathName)] &&
    locationPath !== '/zipcode' &&
    props.dataInput.inverterPower <= 10 &&
    props.dataInput.typeCustomer !== 'Andere Choice'
  ) {
    buttonEnable = !buttonEnable
  } else if (
    locationPath === '/estimated-kWh-consumption' &&
    props.dataInput.estimatedKWhConsumption >= 1000
  ) {
    buttonEnable = !buttonEnable
  }

  // scroll to the top when switching between pages
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [locationPath])

  return (
    <Fragment>
      {
        // Do not display "Vorige an volgende" button if zipcode page
        props.location.pathname !== '/zipcode' &&
        props.location.pathname !== '/result' &&
        props.location.pathname !== '/user-form' ? (
          <Row style={{paddingTop: '4rem'}}>
            <Col style={{textAlign: 'center'}} md={12}>
              <Button clicked={props.onClick} disabled={!buttonEnable}>
                VOLGENDE
              </Button>
            </Col>
            <Col style={{textAlign: 'center', paddingTop: '1rem'}} md={12}>
              <LinkButton onClick={goBack}> VORIGE</LinkButton>
            </Col>
          </Row>
        ) : null
      }
    </Fragment>
  )
}
export default withRouter(Navigation)
