import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'

const ownerOrRenting = props => {
    return (
        <Container>
            <Section>
                <Row>
                    <Col md={12}>
                        <Title align='center' marginBottom='2.5rem'>
                            Bent u de eigenaar of huurt u?
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <CardInput
                            faIcon=''
                            type='radio'
                            value='Eigenaar'
                            name='ownerOrRenting'
                            onChange={props.onChangeHandler}
                            onClick={props.onClickHandler}
                            checked={props.dataInput.ownerOrRenting === 'Eigenaar'}
                        >
                            EIGENAAR
                        </CardInput>
                    </Col>
                    <Col md={6}>
                        <CardInput
                            faIcon=''
                            type='radio'
                            value='Huurder'
                            name='ownerOrRenting'
                            className='radio-button'
                            onChange={props.onChangeHandler}
                            onClick={props.onClickHandler}
                            checked={props.dataInput.ownerOrRenting === 'Huurder'}
                        >
                            HUURDER
                        </CardInput>
                    </Col>
                </Row>
                <Navigation
                    dataInput={props.dataInput}
                    onClick={props.onClickHandler}
                />
            </Section>
        </Container>
    )
}

export default ownerOrRenting
