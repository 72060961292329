import React from 'react'

import Calculator from './containers/Calculator/Calculator'

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

let stripePromise

if (process.env.REACT_APP_STAGE === 'dev') {
  stripePromise = loadStripe(process.env.REACT_APP_test)
} else {
  stripePromise = loadStripe(process.env.REACT_APP_publishable)
}

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Calculator />
    </Elements>
  )
}

export default App
