import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Select from 'react-select'

import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {InputWrapper} from '../../UI/Input/Input'
import {Section} from '../../UI/Section/Section'

const SMEIndustry = props => {
  const options = [
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Bakker',
      label: 'Bakker',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Slager/Traiteur',
      label: 'Slager/Traiteur',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Horeca',
      label: 'Horeca',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Kapper',
      label: 'Kapper',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'ICT',
      label: 'ICT',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Accountant',
      label: 'Accountant',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Tandarts',
      label: 'Tandarts',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Apotheek',
      label: 'Apotheek',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Supermarkt',
      label: 'Supermarkt',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Detailhandel',
      label: 'Detailhandel',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Wellness/Schoonheidszorg',
      label: 'Wellness/Schoonheidszorg',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Dokter/Specialist',
      label: 'Dokter/Specialist',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Brouwerij',
      label: 'Brouwerij',
    },
    {
      name: 'industry',
      optionName: 'sectorOption',
      value: 'Advocaat/Deurwaarder/Notaris',
      label: 'Advocaat/Deurwaarder/Notaris',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Garage',
      label: 'Garage',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Schrijnwerker/interieur',
      label: 'Schrijnwerker/interieur',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Begrafenisondernemer',
      label: 'Begrafenisondernemer',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Groentenwinkel',
      label: 'Groentenwinkel',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Drukkerij',
      label: 'Drukkerij',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Chocolatier/Ijsmaker',
      label: 'Chocolatier/Ijsmaker',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Carwash',
      label: 'Carwash',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Frituur',
      label: 'Frituur',
    },
    {
      name: 'industry',
      optionName: 'industryOption',
      value: 'Andere',
      label: 'Andere',
    },
  ]

  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is uw activiteit?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputWrapper>
              <Select
                value={props.dataInput.industryOption}
                onChange={props.handleChangeSelect}
                options={options}
                isSearchable={false}
              />
            </InputWrapper>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default SMEIndustry
