import React from 'react'
import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: table;
  table-layout: fixed;
  margin: auto;
  position: relative;
  width: 50%;

  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

export const Input = styled.input.attrs(() => ({
  className: 'data-hj-whitelist',
}))`
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  border-radius: 5px;
  display: block;
  height: 3.6em;
  width: 40%;
  margin: 0 auto;

  &:focus {
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    box-shadow: 0 2px 11px 0 rgba(32, 33, 36, 0.1);
    outline: 0;
  }

  @media only screen and (max-width: 990px) {
    display: block;
    width: 100%;
    height: 3.6em;
    margin: 0 auto;
    -webkit-appearance: none;
  }
`

const CardInputWarpper = styled.div`
  height: 220px;
  background-color: ${({checked}) => (checked ? '#01a384' : '#f7f7f8')};
  margin-top: 1rem;
  width: ${({width}) => width || '100%'};

  @media only screen and (max-width: 1200px) {
    display: inline-table;
    width: 100%;
    font-size: 1.2rem;
  }
`
const CardInputItem = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
`

const CardInputIcon = styled.div`
  font-size: 4rem;
  color: ${({checked}) => (checked ? '#f7f7f8' : '#01a384')};
`

const CardInputContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: transform 300ms ease;
`

const CardInputLabel = styled.label`
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${({checked}) => (checked ? '#f7f7f8' : '#212850')};
`

export const CardInput = props => {
  return (
    <CardInputWarpper width={props.width} checked={props.checked}>
      <CardInputItem
        type={props.type}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        onClick={props.onClick}
      />
      <CardInputContainter>
        <CardInputIcon checked={props.checked}>
          <i className={props.faIcon} />
        </CardInputIcon>
        <CardInputLabel checked={props.checked}>
          {props.children}
        </CardInputLabel>
      </CardInputContainter>
    </CardInputWarpper>
  )
}
