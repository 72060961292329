import React, {Component} from 'react'
import {Row, Col, Container, Modal, Spinner} from 'react-bootstrap'
import {Link} from 'react-scroll'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import Button from '../../UI/Button/Button'
import {HeroTitle, Title, SubTitle, BoldTitle} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'
import {MobileMargin60} from '../../UI/Margin/Margin'
import {LinkButton} from '../../UI/LinkButton/LinkButton'
import {Image} from '../../UI/Image/Image'
import {List, ListLine, DivLinebreak} from '../../UI/List/List'
import CheckoutForm from '../../../containers/CheckOut/CheckOut'

import {NumberFormat} from '../../../services/numberFormat'

import PSImg from '../../../assets/images/ps-2.png'
import happyMapImg from '../../../assets/images/icon-happy-map.svg'
import lodeImg from '../../../assets/images/lode.jpg'
import roelImg from '../../../assets/images/Roel-Van-Hove.jpg'
import dimitriImg from '../../../assets/images/dimitri-de-donder.jpg'
import vbdckImg from '../../../assets/images/VBDCK-001-1.jpg'
import storeEnergyImg from '../../../assets/images/store-energy.svg'
import jeroenImg from '../../../assets/images/JeroenAnneleen.jpg'
import dirkImg from '../../../assets/images/dirk-vandenbergh.jpg'
import techspecImg from '../../../assets/images/techspec-power-station.png'
import checkoutImg from '../../../assets/images/checkout-power-station.jpg'
import {IsUUID} from '../../../services/IsUUID'
import {apiUrl} from '../../../index'

const ValueAmount = styled.span`
  display: block;
  font-size: ${({fontSize}) => fontSize || '62px'};
  font-weight: bold;
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
`

const ChoiceInputCard = styled.div`
  width: auto;
  height: auto;
  background-color: ${({checked}) => (checked ? '#01a384' : '#ffff')};
  color: ${({checked}) => (checked ? '#ffff' : '#212850')};
  border: ${({checked}) => (checked ? 'none' : '1px solid #a8acc1')};
  line-height: 20px;
  font-size: 20px;
  border-radius: 100px;
  cursor: pointer;

  @media only screen and (max-width: 990px) {
    margin-bottom: 20px !important;
  }
`

const CardSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
`

const CardLabel = styled.label`
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const RadioInput = styled.input.attrs(props => ({
  type: 'radio',
  value: props.value,
  name: 'preference',
}))`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
`

const FullRow = styled(Row)`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  background-color: #fafafc;
  padding-top: 60px;
  padding-bottom: 60px;
`

const ContentWrapper = styled.div`
  padding: ${({padding}) => padding || '0'};

  @media only screen and (max-width: 990px) {
    padding: 0 !important;
  }
`

class Result extends Component {
  state = {
    inputValues: {
      preference: 'Leasing',
    },
    resultValues: {
      estimatedElectricalConsumption: 0,
      coverageByPS: 0,
      fromTheGrid: 0,
      suggestedNumberOfPS: null,
      increasedInvestmentAllowance: 0,
      totalPsPrice: 0,
      totalPsPriceAfterBenefits: 0,
      batteryPrice: 0,
      flemishInvestmentWKK: 0,
      gasConsumption: [],
      serviceContract: [],
      waterConsumption: [],
      totalCostsViaGrid: [],
      restOffTheGrid: [],
      benefitOfHeat: [],
      monthlyAmountGrid: [],
      annualSavings: [],
      annualSavingsWithLeasing: [],
      totalCostsViaElugie: [],
      co2Emissions: 0,
      cumulativeAnnualSavings: [],
      totalEstimatedPlacementCost: 0,
      batterySubsidy: 0,
      monthlyLeasing: 0,
      leasingDuration: 0,
      leasingIntrest: 0,
      typeCustomer: '',
    },
    modal: {
      selfPowerModal: false,
      cumulativePrice: false,
      withElugieModal: false,
      showLeasingDetails: false,
      showCashDetails: false,
      showYearElecDetails: false,
    },
    loading: false,
    processing: false,
  }

  // input or radio
  onChangeHandler = e => {
    const {name, value} = e.target

    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [name]: value,
      },
    })
  }

  handleShow = e => {
    const {name} = e.target

    this.setState(prevState => {
      return {
        modal: {
          ...this.state.modal,
          [name]: !prevState.modal[name],
        },
      }
    })
  }

  handleClose = modalName => {
    this.setState({
      modal: {
        ...this.state.modal,
        [modalName]: false,
      },
    })
  }

  componentDidMount() {
    this.setState({loading: true})
    let {simulationId} = this.props.match.params

    if (!IsUUID(simulationId)) {
      simulationId = Cookies.get('elugie_sim')
      this.props.history.push(`/result/${simulationId}`)
    }

    if (!simulationId) {
      this.props.history.push(`/zipcode`)
    } else {
      axios
        .get(`${apiUrl}/v1/simulation/${simulationId}/`)
        .then(response => {
          const {data} = response

          this.setState({
            resultValues: {
              ...this.state.resultValues,
              estimatedElectricalConsumption: data.amount_kwh_usage * 1,
              suggestedNumberOfPS: data.suggested_number_of_ps * 1,
              coverageByPS: data.coverage_by_ps * 1,
              fromTheGrid: data.from_the_grid * 1,
              co2Emissions: data.co2_emissions * 1,
              totalEstimatedPlacementCost:
                data.total_estimated_placement_cost * 1,
              flemishInvestmentWKK: data.flemish_investment_wkk * 1,
              batterySubsidy: data.battery_subsidy * 1,
              monthlyLeasing: data.monthly_financing,
              cumulativeAnnualSavings: data.cumulative_annual_savings,
              totalPsPrice: data.total_ps_price * 1,
              totalPsPriceAfterBenefits: data.total_price_after_benefits * 1,
              batteryPrice: data.battery_price * 1,
              increasedInvestmentAllowance:
                data.increased_investment_allowance * 1,
              totalCostsViaGrid: data.amount_kwh_cost_grid,
              restOffTheGrid: data.total_costs_grid,
              totalCostsViaElugie: data.monthly_price_elugie,
              gasConsumption: data.gas_consumption,
              serviceContract: data.service_contract,
              waterConsumption: data.water_consumption,
              benefitOfHeat: data.benefit_of_heat,
              annualSavings: data.annual_savings,
              leasingDuration: data.financing_duration * 1,
              leasingIntrest: data.financing_interest * 1,
              typeCustomer: data.type_customer,
            },
          })

          this.setState({loading: false})
        })
        .catch(e => {
          if (!e.response) {
            this.setState({
              loading: false,
              error: 'Something went wrong',
            })
          } else {
            this.setState({
              loading: false,
              error: e.response.data.error,
            })
          }
          Cookies.remove('elugie_sim', {path: ''})
          Sentry.withScope(() => {
            Sentry.captureException(e)
          })
          this.props.history.push(`/`)
        })
    }
  }

  downloadPDF = () => {
    const {simulationId} = this.props.match.params

    axios({
      url: `${apiUrl}/v1/simulation/${simulationId}/aflossingstabel`,
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const urlLink = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = urlLink
      link.setAttribute('download', 'aflossingstabel.pdf')
      document.body.appendChild(link)
      link.click()
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <Section textAlign='center'>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </Section>
      )
    }

    if (this.state.error || this.state.resultValues.suggestedNumberOfPS === 0) {
      return (
        <Section>
          <HeroTitle>
            {this.state.error ? this.state.error : 'Te laag verbruik!'}
          </HeroTitle>
        </Section>
      )
    }

    const {
      suggestedNumberOfPS,
      totalPsPrice,
      batteryPrice,
      co2Emissions,
      fromTheGrid,
      coverageByPS,
      estimatedElectricalConsumption,
      totalEstimatedPlacementCost,
      flemishInvestmentWKK,
      batterySubsidy,
      monthlyLeasing,
      cumulativeAnnualSavings,
      increasedInvestmentAllowance,
      totalCostsViaGrid,
      totalCostsViaElugie,
      gasConsumption,
      serviceContract,
      waterConsumption,
      benefitOfHeat,
      restOffTheGrid,
      annualSavings,
      typeCustomer,
      leasingDuration,
      leasingIntrest,
      totalPsPriceAfterBenefits,
    } = this.state.resultValues

    const vatIn = typeCustomer !== 'Particulier' ? '(excl. BTW)' : '(incl. BTW)'

    let storagePower

    switch (suggestedNumberOfPS) {
      case 1:
        storagePower = '20kVA 19kWh'
        break
      case 2:
        storagePower = '20kVA 28kWh'
        break
      case 3:
        storagePower = '20kVA 45kWh'
        break
      case 4:
        storagePower = '20kVA 45kWh'
        break
      case 5:
        storagePower = '50kVA 54kWh'
        break
      case 6:
        storagePower = '50kVA 54kWh'
        break
    }

    return (
      <Container>
        <Section paddingTop='60px' paddingBottom='60px'>
          <Row>
            <Col md={12}>
              <div>
                <HeroTitle>
                  U heeft {suggestedNumberOfPS} Elugie{' '}
                  {suggestedNumberOfPS > 1 ? 'centrales' : 'centrale'} nodig
                </HeroTitle>
                <SubTitle>Dit volgens uw energieverbruik:</SubTitle>
                <BoldTitle>
                  {parseFloat(estimatedElectricalConsumption).toFixed(0)} kWh /
                  jaar
                </BoldTitle>
              </div>
              <br />
              <LinkButton name='kWhModal' onClick={this.handleShow}>
                Meer weten
              </LinkButton>
              <Modal
                onHide={() => this.handleClose('kWhModal')}
                show={this.state.modal.kWhModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <Title align='center'>Hoe maken wij de berekening?</Title>
                  <p>
                    1 Elugie centrale kan tot<strong> 13.000kwh </strong>
                    produceren. Een minimum van 10.000kwh per jaar is een
                    noodzaak om een centrale financieel interessant in te
                    zetten. Uw verbruik wordt gedeeld door 13.000kwh en zo wordt
                    het aantal centrales bepaald.
                  </p>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Row>
                <Col xs={6}>
                  <ValueAmount fontSize='40px'>
                    {parseFloat(coverageByPS).toFixed(0)}%
                  </ValueAmount>
                  <SubTitle>Zelfstroom</SubTitle>
                </Col>
                <Col className='border-left' xs={6}>
                  {
                    <ValueAmount fontSize='40px' opacity='0.6'>
                      {parseFloat(fromTheGrid).toFixed(0)}%
                    </ValueAmount>
                  }
                  <SubTitle opacity='0.6'>Van het net</SubTitle>
                </Col>
              </Row>
              <br />
              <LinkButton name='selfPowerModal' onClick={this.handleShow}>
                Meer weten
              </LinkButton>
              <Modal
                onHide={() => this.handleClose('selfPowerModal')}
                show={this.state.modal.selfPowerModal}
                aria-labelledby='contained-modal-title-vcenter'
                size='lg'
                centered
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <Title align='center'>
                    Hoe werkt onafhankelijkheid bij Elugie?
                  </Title>
                  <p>
                    In uw simulatie wordt{' '}
                    <strong>{parseFloat(coverageByPS).toFixed(0)}% </strong>
                    via uw Elugie centrale geproduceerd en wordt nog{' '}
                    <strong>{parseFloat(fromTheGrid).toFixed(0)}% </strong>
                    uit het net genomen. Dit omdat 1 centrale 13.000kWh per jaar
                    kan produceren en een extra centrale financieel minder
                    interessant is dan de resterende elektriciteit uit het net
                    te nemen. Om 100% dekking te voorzien kan u uiteraard kiezen
                    om een extra centrale te plaatsen. Dankzij het geïntegreerde
                    batterij energie management systeem kan is noodstroom en
                    integratie in de Smart grid van morgen mogelijk en gaat u
                    voor volledige energie vrijheid.
                  </p>
                  <Title align='center'>
                    Elugie Freedom voor volledige energie vrijheid
                  </Title>
                  <p>
                    De Elugie elektriciteitscentrale produceert continu 1,5 kW
                    elektriciteit dankzij duurzame energie opgeslagen en
                    getransporteerd via het bestaande gasnet. Niet gebruikte
                    elektriciteit gaat het net op en een tekort wordt uit het
                    net gehaald. Dit wil zeggen dat gemiddeld tot 80% van de
                    geproduceerde elektriciteit in het gebouw verbruikt wordt en
                    slechts 20% in het net geïnjecteerd wordt om later terug af
                    te nemen. Met de Elugie Freedom uitbreiding kan u teveel
                    geproduceerde elektriciteit stockeren om 100% net
                    onafhankelijk te worden. Wie dagelijks exact kan produceren
                    wat er verbruikt wordt en dit onafhankelijk van beschikbare
                    zon of windenergie, zal de grote winnaar in de
                    energietransitie zijn. Energie vrijheid met Elugie staat
                    voor onafhankelijk en maximaal duurzaam aan een lage en
                    stabiele prijs.
                  </p>

                  <Image alt='elugie-store-energy' src={storeEnergyImg} />
                  <br />
                  <br />
                  <ol>
                    <li>
                      Continue productie van elektriciteit
                      <strong> via altijd </strong> beschikbare waterstof
                      opgeslagen en getransporteerd via het bestaande gasnet.
                    </li>
                    <li>
                      Uw<strong> dagelijks </strong> verbruik is niet constant
                      maar volgt een bepaald<strong> afnameprofiel </strong>.
                    </li>
                    <li>
                      In perioden dat er minder verbruik is dan productie kan
                      energie opgeslagen worden voor later gebruik
                    </li>
                    <li>
                      In perioden van piekverbruik kan opgeslagen energie
                      aangewend worden, zo wordt maximale energie vrijheid
                      bereikt.
                    </li>
                  </ol>
                </Modal.Body>
              </Modal>
            </Col>
            <Col md={6}>
              <Image alt='elugie-power-station' src={PSImg} width='66%' />
            </Col>
            <Col md={3}>
              <MobileMargin60>
                <ValueAmount fontSize='40px'>{co2Emissions}%</ValueAmount>
                <SubTitle>Minder CO₂ uitstoot</SubTitle>
                <br />
                <LinkButton name='CO2Modal' onClick={this.handleShow}>
                  Meer weten
                </LinkButton>
                <Modal
                  onHide={() => this.handleClose('CO2Modal')}
                  show={this.state.modal.CO2Modal}
                  size='lg'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <Title align='center'>
                      Hoe werkt duurzaamheid bij Elugie?
                    </Title>
                    <p>
                      De waterstof technologie van Elugie werkt op een zeer hoge
                      temperatuur nl. 750 graden wat het toelaat om zonder
                      energie toevoeging waterstof uit het gas te halen.
                      Hierdoor worden de hoogste elektrische rendement behaald
                      wereldwijd. Er wordt niks verbrand, de stroomopwekking
                      gebeurd door een chemische reactie tussen zuurstof en
                      waterstof waarbij met zeer hoog rendement stroom vrijkomt.
                      Bij gebruik van aardgas(fossiel gas) wordt een CO2
                      reductie van 20 tot 50% bereikt ten opzichte van
                      elektriciteit uit het stroomnet. Door gebruik te maken van
                      groengas met garantie van oorsprong afkomstig van zon of
                      windenergie projecten, biomethaan van landbouw afval
                      projecten of syngas uit biomassa projecten wordt uw CO2
                      uitstoot gereduceerd naar bijna 100%. Opslag van alle
                      hernieuwbare vormen in gasvorm heeft het ongekende
                      voordeel dat op deze manier duurzame energie 1000den keren
                      gemakkelijker en goedkoper kan opgeslagen worden dan
                      energie via het elektriciteitsnet. De weg naar 100%
                      duurzaam voor elektriciteit kan via Elugie vandaag al
                      bewandeld worden. Geen compensatie met groene wind of
                      zon-energie en in de winter alsnog van de nucleaire,
                      steenkool of gascentrales verbruiken, neen wij gaan voor
                      duurzame stroom elke seconde van de dag volgens ons
                      verbruiksprofiel. U toch ook?
                    </p>

                    <Title align='center'>
                      Hoe werd uw CO2 besparing berekend?
                    </Title>
                    <p>
                      Met aardgas kan u vandaag tot 20% CO2 besparen met Elugie,
                      na de kernuitstap tot 50% en met groengas vandaag al tot
                      97% t.o.v. elektriciteit uit het stroomnet.
                    </p>
                  </Modal.Body>
                </Modal>
              </MobileMargin60>
            </Col>
          </Row>
        </Section>
        <Section paddingTop='60px' paddingBottom='60px'>
          <Row
            style={{
              backgroundColor: '#fafafc',
              padding: '60px 0',
              alignItems: 'center',
            }}
          >
            <Col md={12}>
              <div>
                <Title align='center'>
                  Elugie leidt de Belgische waterstofrevolutie
                </Title>
              </div>
            </Col>
            <Col md={3}>
              <Image alt='happy-map' src={happyMapImg} margin='none' />
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  ELUGIE CENTRALES
                </SubTitle>
                <ValueAmount>450+</ValueAmount>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  HUIDIGE CAPACITEIT IN BELGIË
                </SubTitle>
                <ValueAmount>
                  675<sub>kW</sub>
                </ValueAmount>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  CO2 BESPAARD
                </SubTitle>
                <ValueAmount>
                  4.920<sub>ton</sub>
                </ValueAmount>
              </div>
            </Col>
          </Row>
        </Section>
        <Section paddingTop='60px' paddingBottom='60px'>
          <Row style={{margin: '60px 0'}}>
            <Col md={6}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image alt='Lode Fastré' src={lodeImg} />
                ) : (
                  <Image alt='Roel Van Hove' src={roelImg} />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={6}>
              <ContentWrapper padding='0 0 0 20px'>
                <Title>Waterstof: 100% duurzame energie</Title>
                <p>
                  De Elugie waterstof elektriciteitscentrale maakt ter plaatse
                  elektriciteit uit water en groengas. Gas uit het net wordt
                  gekraakt tot waterstof, die op zijn beurt wordt gebruikt om
                  elektriciteit op te wekken.
                </p>
                <p>
                  Het eindresultaat is duurzame zelfstroom, onafhankelijkheid
                  van prijsstijgingen op het stroomnet, en een veel efficiëntere
                  manier van energieproductie (die 100% duurzaam kan zijn).
                </p>
                <Row>
                  <Col md={12}>
                    {typeCustomer !== 'KMO' ? (
                      <span style={{fontStyle: 'italic'}}>
                        “De restwarmte die we inzetten voor ons sanitair warm
                        water, is een mooie extra. Ik heb als boekhouder al
                        meerdere van mijn eigen klanten doorgestuurd naar
                        Elugie!”
                      </span>
                    ) : (
                      <span style={{fontStyle: 'italic'}}>
                        “De twee volgende Elugie-centrales moesten naar ons toe
                        komen.”
                      </span>
                    )}
                  </Col>
                  <Col style={{marginTop: '10px'}} md={12}>
                    {typeCustomer !== 'KMO' ? (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Lode Fastré
                      </BoldTitle>
                    ) : (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Roel Van Hove, BIO-Shop Schilde
                      </BoldTitle>
                    )}
                  </Col>
                </Row>
              </ContentWrapper>
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col md={{size: 6, order: 12}}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dimitri De Donder Immo Optima'
                    style={{
                      width: '100%',
                    }}
                    src={dimitriImg}
                  />
                ) : (
                  <Image
                    alt='Brouwerij VBDCK-KEREL'
                    style={{
                      width: '100%',
                    }}
                    src={vbdckImg}
                  />
                )}
              </div>
            </Col>
            <Col
              style={{alignItems: 'center', display: 'flex'}}
              md={{size: 6, order: 1}}
            >
              {typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Volledig onafhankelijk, volledig autonoom</Title>
                  <p>
                    Zelfstroom opwekken betekent onafhankelijkheid: vrijheid van
                    de stijgende stroomprijzen, autonoom uw elektrische wagens
                    opladen, en zekerheid over uw elektriciteitsvoorziening —
                    stil en zorgeloos, elk moment van de dag.
                  </p>
                  <p>
                    Energie uit waterstof is stil, compact en helemaal schoon:
                    de enige uitstoot is waterdamp, en de restwarmte die u
                    produceert is direct zinnig toepasbaar in uw vloerverwarming
                    of andere warmtebronnen.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Er zijn al verschillende collega’s komen kijken naar
                        onze installatie. Eén van mijn beste vrienden heeft
                        ondertussen dezelfde Elugie centrale gezet.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dimitri De Donder, Immo Optima
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Groeiende besparingen en een hoog rendement</Title>
                  <p>
                    Naast een duidelijke positieve impact op het milieu, zorgt
                    uw eigen Elugie centrale ook voor aanzienlijke besparingen.
                  </p>
                  <p>
                    De leasing of aankoop van de centrale wordt betaald met de
                    besparing die wordt gerealiseerd en op een periode van
                    15jaar bespaar je meer dan 30% op kosten voor elektriciteit.
                    Als u uw factuur blijft betalen ben je je centen kwijt.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Zelfs de restwarmte van de centrales gebruiken we in
                        ons brouwproces”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Philippe De Cock, Brouwerij VBDCK-KEREL
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col md={6}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dirk Vandenbergh'
                    style={{
                      width: '100%',
                    }}
                    src={dirkImg}
                  />
                ) : (
                  <Image
                    alt='Jeroen Anneleen'
                    style={{
                      width: '100%',
                    }}
                    src={jeroenImg}
                  />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={6}>
              {typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Besparingen op uw energierekening</Title>
                  <p>
                    De Belgische kernuitstap drijft stroomprijzen op, en
                    toenemend gebruik van duurzame bronnen zet het stroomnet
                    verder onder druk. Het resultaat is — al jarenlang —
                    stijgende stroomprijzen. Een zelfstroom centrale verzekert u
                    van een goedkope energievoorziening, in eigen beheer.
                  </p>
                  <p>
                    Ontkoppeling van stroomprijzen is vooral interessant voor
                    gebruikers met bijvoorbeeld een elektrisch wagenpark, een
                    warmtepomp of een anderszins bovengemiddeld energiegebruik.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Juist afrekening gehad van elektriciteit en vlotjes 67%
                        minder verbruik dan vorig jaar! Dus supertevreden klant!
                        Ik kan alleen maar merci zeggen aan gans uw equipe toffe
                        gasten!”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dirk Vandenbergh
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Volledige onafhankelijkheid</Title>
                  <p>
                    Uw Elugie waterstofcentrale komt met een stevige set
                    batterijen. Hiermee slaat u overschotten voor de korte
                    termijn op, zodat u ook op dure piekuren niet uit het net
                    hoeft te tappen.
                  </p>
                  <p>
                    Met de invoering van smart meters en nieuwe regelgeving in
                    2021 wordt elke gebruiker beloond om het stroomnet minimaal
                    te belasten. Met een Elugie centrale bent u volledig
                    onafhankelijk, en ondersteunt u deze duurzame revolutie.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Stijgende energieprijzen wegen niet meer op de toekomst
                        van onze zaak.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Slagerij Jeroen en Anneleen-Oudenburg
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
        </Section>
        <Section paddingTop='60px' paddingBottom='60px'>
          <Row>
            <Col md={12}>
              <div>
                <Title align='center'>Technische specificaties</Title>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div
                style={{
                  marginTop: '20px',
                  marginButton: '20px',
                  width: '100%',
                }}
              >
                <Image alt='techspec-power-station' src={techspecImg} />
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={3}>
              <div>
                <p>
                  <span style={{color: '#A8ACC2'}}>Elektrische energie:</span>
                  <br />
                  13.000 kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Thermische energie:</span>
                  <br />
                  tot 6.500 kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Productie-uren:</span>
                  <br />
                  8.700 uren, hele jaar door
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Verbruik gas:</span>
                  <br />
                  22.000 kWh (bij start)
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Waterstoftechnologie:</span>
                  <br />
                  SOFC
                </p>
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={3}>
              <div className='wpb_wrapper' style={{marginTop: '20%'}}>
                <p>
                  <span style={{color: '#A8ACC2'}}>
                    Elektrische efficiëntie:
                  </span>
                  <br />
                  +60%
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>
                    Thermische efficientie:
                  </span>
                  <br />
                  25-32%
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Gewicht:</span>
                  <br />
                  265kg
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Capaciteit/opslag:</span>
                  <br />
                  5kW-45kW ; 14kwh-72kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>My Elugie:</span>
                  <br />
                  Monitoring, energie management, groepsaankoop groengas
                </p>
              </div>
            </Col>
          </Row>
        </Section>
        <Section paddingTop='60px' paddingBottom='60px'>
          <FullRow>
            <Container>
              <Col md={12}>
                <Section paddingTop='60px' paddingBottom='60px'>
                  <Title align='center'>
                    Elugie prijsberekening en besparing
                  </Title>
                  <SubTitle>
                    Kies voor cash aankoop, of plaats uw centrales via leasing
                  </SubTitle>
                  <Row style={{padding: '40px 0'}}>
                    <Col md={6}>
                      <ChoiceInputCard
                        checked={
                          this.state.inputValues.preference === 'Leasing'
                        }
                      >
                        <RadioInput
                          value='Leasing'
                          onChange={this.onChangeHandler}
                          checked={
                            this.state.inputValues.preference === 'Leasing'
                          }
                        />
                        <CardSelectContainer>
                          <CardLabel>Leasing</CardLabel>
                          <span>Overstappen zonder investering</span>
                        </CardSelectContainer>
                      </ChoiceInputCard>
                    </Col>
                    <Col md={6}>
                      <ChoiceInputCard
                        checked={this.state.inputValues.preference === 'Cash'}
                      >
                        <RadioInput
                          value='Cash'
                          onChange={this.onChangeHandler}
                        />
                        <CardSelectContainer>
                          <CardLabel>Cash</CardLabel>
                          <span>Investeer in uw zaak</span>
                        </CardSelectContainer>
                      </ChoiceInputCard>
                    </Col>
                  </Row>
                  {this.state.inputValues.preference === 'Cash' ? (
                    <div>
                      <List>
                        {this.state.modal.showCashDetails ? (
                          <>
                            <ListLine>
                              <span>
                                {suggestedNumberOfPS} x Elugie centrale(s)
                              </span>
                              <span>
                                <NumberFormat>
                                  {totalPsPrice + batteryPrice}
                                </NumberFormat>
                              </span>
                            </ListLine>
                            <ListLine>
                              <span>Plaatsingskost</span>
                              <span>
                                <NumberFormat>
                                  {totalEstimatedPlacementCost}
                                </NumberFormat>
                              </span>
                            </ListLine>
                            <ListLine>
                              <span>
                                Vlaamse subsidies voor waterstof technologie
                              </span>
                              <span>
                                -{' '}
                                <NumberFormat>
                                  {flemishInvestmentWKK}
                                </NumberFormat>
                              </span>
                            </ListLine>
                            {typeCustomer === 'Particulier' ? (
                              <ListLine>
                                <span>Vlaamse subsidies voor batterijen</span>
                                <span>
                                  -{' '}
                                  <NumberFormat>{batterySubsidy}</NumberFormat>
                                </span>
                              </ListLine>
                            ) : null}
                            {typeCustomer !== 'Particulier' ? (
                              <ListLine>
                                <span>Verhoogde investering aftrek</span>
                                <span>
                                  -{' '}
                                  <NumberFormat>
                                    {increasedInvestmentAllowance}
                                  </NumberFormat>
                                </span>
                              </ListLine>
                            ) : null}
                          </>
                        ) : null}
                        <ListLine>
                          <BoldTitle align='left'>
                            Totaal prijs na subsidies {vatIn}{' '}
                          </BoldTitle>
                          <BoldTitle>
                            <NumberFormat>
                              {totalPsPriceAfterBenefits}
                            </NumberFormat>
                          </BoldTitle>
                        </ListLine>
                        <ListLine>
                          <LinkButton
                            margin='none'
                            name='showCashDetails'
                            onClick={this.handleShow}
                          >
                            {this.state.modal.showCashDetails
                              ? ' - Verberg details'
                              : ' + Meer details'}
                          </LinkButton>
                        </ListLine>
                      </List>
                    </div>
                  ) : (
                    <>
                      <div>
                        <List>
                          {this.state.modal.showLeasingDetails ? (
                            <>
                              <ListLine>
                                <span>Plaatsingskost</span>
                                <span>
                                  <NumberFormat>
                                    {totalEstimatedPlacementCost}
                                  </NumberFormat>
                                </span>
                              </ListLine>
                              <ListLine>
                                <span>
                                  Vlaamse subsidies voor waterstof technologie
                                </span>
                                <span>
                                  -{' '}
                                  <NumberFormat>
                                    {flemishInvestmentWKK}
                                  </NumberFormat>
                                </span>
                              </ListLine>
                              {typeCustomer === 'Particulier' ? (
                                <ListLine>
                                  <span>Vlaamse subsidies voor batterijen</span>
                                  <span>
                                    -{' '}
                                    <NumberFormat>
                                      {batterySubsidy}
                                    </NumberFormat>
                                  </span>
                                </ListLine>
                              ) : null}
                            </>
                          ) : null}

                          <ListLine>
                            <BoldTitle align='left'>
                              {typeCustomer !== 'Particulier'
                                ? 'Eigen inbreng plaatsingskost na subsidies (excl.BTW)'
                                : 'Totaal prijs na subsidies (incl. BTW)'}
                            </BoldTitle>
                            <BoldTitle align='left'>
                              {typeCustomer !== 'Particulier' ? (
                                <NumberFormat>
                                  {totalEstimatedPlacementCost +
                                    flemishInvestmentWKK}
                                </NumberFormat>
                              ) : (
                                <NumberFormat>
                                  {totalPsPriceAfterBenefits}
                                </NumberFormat>
                              )}
                            </BoldTitle>
                          </ListLine>
                          {typeCustomer !== 'Particulier' ? (
                            <ListLine>
                              <LinkButton
                                margin='none'
                                name='showLeasingDetails'
                                onClick={this.handleShow}
                              >
                                {this.state.modal.showLeasingDetails
                                  ? ' - Verberg details'
                                  : ' + Meer details'}
                              </LinkButton>
                            </ListLine>
                          ) : null}
                        </List>
                      </div>
                      <DivLinebreak>
                        <List>
                          <ListLine>
                            <BoldTitle align='left'>
                              Uw leasingkost voor {suggestedNumberOfPS} Elugie
                              centrales
                            </BoldTitle>
                            <BoldTitle align='left'>
                              <NumberFormat>{monthlyLeasing[0]}</NumberFormat>
                            </BoldTitle>
                          </ListLine>
                          <ListLine>
                            <SubTitle opacity='0.5' marginBottom='0'>
                              {leasingDuration} maanden, {leasingIntrest}%{' '}
                              {vatIn}
                            </SubTitle>
                          </ListLine>
                          <ListLine>
                            <BoldTitle align='left'>
                              <LinkButton
                                margin='none'
                                name='downloadAflossingstabel'
                                onClick={this.downloadPDF}
                              >
                                Download aflossingstabel leasing
                              </LinkButton>
                            </BoldTitle>
                          </ListLine>
                        </List>
                      </DivLinebreak>
                    </>
                  )}
                  <DivLinebreak>
                    <List>
                      <ListLine>
                        <BoldTitle align='left'>
                          Uw bruto besparing op 20 jaar met Elugie t.o.v.
                          elektriciteit uit het net
                        </BoldTitle>
                        <BoldTitle align='left'>
                          <NumberFormat>
                            {cumulativeAnnualSavings[19]}
                          </NumberFormat>
                        </BoldTitle>
                      </ListLine>
                      <ListLine>
                        <LinkButton
                          margin='none'
                          name='showYearElecDetails'
                          onClick={this.handleShow}
                        >
                          Meer weten
                        </LinkButton>
                        <Modal
                          onHide={() => this.handleClose('showYearElecDetails')}
                          show={this.state.modal.showYearElecDetails}
                          size='lg'
                          aria-labelledby='contained-modal-title-vcenter'
                          centered
                        >
                          <Modal.Header closeButton />
                          <Modal.Body>
                            <Title align='center'>
                              Hoe werd uw besparing berekend?
                            </Title>
                            <SubTitle>
                              Zelfstroom met Elugie t.o.v. elektriciteit uit het
                              stroomnet
                            </SubTitle>
                            <br />
                            <div style={{overflowX: 'auto'}}>
                              <table
                                style={{width: '100%', textAlign: 'right'}}
                              >
                                <tbody>
                                  <tr>
                                    <td />
                                    <td>
                                      <BoldTitle align='right'>
                                        5de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        10de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        15de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        20ste jaar
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <BoldTitle align='left' opacity='0.6'>
                                        Elektriciteit uit het net: <sup>1</sup>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        <NumberFormat>
                                          {totalCostsViaGrid[4]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        <NumberFormat>
                                          {totalCostsViaGrid[9]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        <NumberFormat>
                                          {totalCostsViaGrid[14]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        <NumberFormat>
                                          {totalCostsViaGrid[19]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left'>
                                        Zelfstroom met Elugie:
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        <NumberFormat>
                                          {totalCostsViaElugie[4] * 12}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        <NumberFormat>
                                          {totalCostsViaElugie[9] * 12}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        <NumberFormat>
                                          {totalCostsViaElugie[14] * 12}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        <NumberFormat>
                                          {totalCostsViaElugie[19] * 12}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Gasgebruik <sup>2</sup>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {gasConsumption[5] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {gasConsumption[10] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {gasConsumption[15] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {gasConsumption[20] * 12}
                                      </NumberFormat>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Restafname van het net
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {restOffTheGrid[5] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {restOffTheGrid[10] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {restOffTheGrid[15] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {restOffTheGrid[20] * 12}
                                      </NumberFormat>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Servicecontract <sup>3</sup>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {serviceContract[5] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {serviceContract[10] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {serviceContract[15] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {serviceContract[20] * 12}
                                      </NumberFormat>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Waterverbruik <sup>3</sup>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {waterConsumption[5] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {waterConsumption[10] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {waterConsumption[15] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {waterConsumption[20] * 12}
                                      </NumberFormat>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Voordeel warmte
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {benefitOfHeat[5] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {benefitOfHeat[10] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {benefitOfHeat[15] * 12}
                                      </NumberFormat>
                                    </td>
                                    <td>
                                      <NumberFormat>
                                        {benefitOfHeat[20] * 12}
                                      </NumberFormat>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left' color='#00A385'>
                                        Jaarlijks besparing
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {annualSavings[4]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {annualSavings[9]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {annualSavings[14]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {annualSavings[19]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left' color='#00A385'>
                                        Cumul besparing
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {cumulativeAnnualSavings[4]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {cumulativeAnnualSavings[9]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {cumulativeAnnualSavings[14]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        <NumberFormat>
                                          {cumulativeAnnualSavings[19]}
                                        </NumberFormat>
                                      </BoldTitle>
                                    </td>
                                    <td />
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <br />
                            <br />
                            <div>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                1. De jaarlijkse kosten voor elektriciteit via
                                het net (incl. netbeheerkosten etc.) stijgen de
                                eerste 10 jaar met 6% en vervolgens met 5% per
                                jaar. De jaarlijkse elektriciteitskosten worden
                                berekend op{' '}
                                {typeCustomer !== 'KMO' ? '€ 0,28' : '€ 0,23'}{' '}
                                per kWh.
                              </SubTitle>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                2. De groen gas kosten om elektriciteit op te
                                wekken met de Elugie-centrale stijgen gemiddeld
                                met 2% per jaar en de jaarlijkse gasverbruik
                                kosten worden berekend op{' '}
                                {typeCustomer !== 'KMO' ? '€ 0,042' : '€ 0,035'}{' '}
                                per kWh.
                              </SubTitle>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                3. De jaarlijkse kosten voor servicecontract en
                                waterverbruik worden berekend volgens de
                                consumentenindex van 2% per jaar.
                              </SubTitle>
                            </div>
                            <br />
                            <div>
                              <SubTitle align='left' opacity='0.5'>
                                Van het voordeel dient u de{' '}
                                {typeCustomer !== 'Particulier'
                                  ? 'leasing'
                                  : 'financiering'}{' '}
                                of cash aankoop nog vanaf te trekken om het
                                netto voordeel te zien. De{' '}
                                {typeCustomer !== 'Particulier'
                                  ? 'leasing'
                                  : 'financiering'}{' '}
                                of cash aankoop worden betaald met de besparing
                                die wordt gerealiseerd. U betaalt enkel het
                                service contract{' '}
                                {this.state.inputValues.preference === 'Leasing'
                                  ? 'en het leasing bedrag aan Elugie'
                                  : 'en het aankoopbedrag aan Elugie'}
                                . Gas of groengas neemt u af bij een energie
                                leverancier al dan niet binnen de Elugie
                                community. Het waterverbruik is een kost bij
                                afname uit het net maar kan ook via regenwater
                                gebeuren. Met toekomstige CO2 taxatie en
                                voordelen voor balanceren van elektriciteit via
                                het net is nog geen rekening gehouden.
                                Berekening enkel ter informatie onder voorbehoud
                                van eventuele prijsafwijkingen.
                              </SubTitle>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </ListLine>
                    </List>
                  </DivLinebreak>
                  <div>
                    <SubTitle opacity='0.5'>
                      De Vlaamse steun ontvangt u na opstart en aanvraag van uw
                      subsidie.{' '}
                      {this.state.inputValues.preference === 'Cash'
                        ? ' Het verhoogd fiscaal voordeel is het netto voordeel dat u geniet voor het desbetreffende fiscaal jaar, enkel voor kmos.'
                        : null}{' '}
                      De Plaatsingskost voor 3 centrales is afhankelijk van uw
                      situatie. Na een bezoek bij u ter plaatse door een lokale
                      Elugie installatie partner kan een prijs op maat van uw
                      situatie worden opgemaakt.
                    </SubTitle>
                  </div>
                </Section>
              </Col>
              <Row style={{textAlign: 'center', paddingBottom: '60px'}}>
                <Col md={12}>
                  <Link to='section1' smooth={true} offset={-70}>
                    <Button>RESERVEER MIJN ELUGIE CENTRALE</Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </FullRow>
        </Section>
        <Section paddingTop='60px' paddingBottom='60px' id='section1'>
          <Row>
            <Col md={12}>
              <div>
                <Title align='center'>
                  Reserveer vandaag nog voor slechts €100
                </Title>
                <SubTitle>
                  Plaatsing van deze nieuwe generatie centrales start begin
                  2021.
                </SubTitle>
              </div>
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={{size: 5, order: 12}}>
              <div>
                <div>
                  <BoldTitle align='left'>Samenvatting</BoldTitle>
                </div>
                <DivLinebreak>
                  <Row>
                    <Col md={12}>
                      <div
                        style={{
                          marginTop: '20px',
                          marginButton: '20px',
                        }}
                      >
                        <Image
                          alt='techspec-power-station'
                          width='54%'
                          src={checkoutImg}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div>
                        <SubTitle>
                          {suggestedNumberOfPS} x Elugie centrales
                        </SubTitle>
                        <SubTitle>{storagePower} Storage</SubTitle>
                      </div>
                      <br />
                    </Col>
                    <br />
                    <br />
                    <Col md={12}>
                      <div
                        style={{
                          paddingBottom: '30px',
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <ValueAmount fontSize='40px'>
                              {parseFloat(coverageByPS).toFixed(0)}%
                            </ValueAmount>
                            <SubTitle>Zelfstroom</SubTitle>
                          </Col>
                          <Col className='border-left' xs={6}>
                            {
                              <ValueAmount fontSize='40px'>
                                {co2Emissions}%
                              </ValueAmount>
                            }
                            <SubTitle>Minder CO2 uitstoot </SubTitle>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </DivLinebreak>
                <DivLinebreak>
                  <Row>
                    <Col md={12}>
                      <div>
                        {this.state.inputValues.preference === 'Leasing' ? (
                          <List>
                            <ListLine>
                              <SubTitle>
                                {typeCustomer !== 'Particulier'
                                  ? 'Eigen inbreng plaatsingskost'
                                  : 'Totaal prijs na subsidies (incl. btw)'}
                              </SubTitle>
                              <SubTitle>
                                {typeCustomer !== 'Particulier' ? (
                                  <NumberFormat>
                                    {totalEstimatedPlacementCost +
                                      flemishInvestmentWKK}
                                  </NumberFormat>
                                ) : (
                                  <NumberFormat>
                                    {totalPsPriceAfterBenefits}
                                  </NumberFormat>
                                )}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>
                                {typeCustomer !== 'Particulier'
                                  ? 'Uw maandelijks leasingkost'
                                  : 'Uw maandelijks financieringskost'}
                              </SubTitle>
                              <SubTitle>
                                <NumberFormat>{monthlyLeasing[0]}</NumberFormat>
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>Uw besparing op 20 jaar</SubTitle>
                              <SubTitle>
                                <NumberFormat>
                                  {cumulativeAnnualSavings[19]}
                                </NumberFormat>
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <BoldTitle>Vandaag te voldoen</BoldTitle>
                              <BoldTitle>€ 100</BoldTitle>
                            </ListLine>
                          </List>
                        ) : (
                          <List>
                            <ListLine>
                              <SubTitle>
                                Totaal prijs na subsidies{' '}
                                {typeCustomer !== 'Particulier'
                                  ? '(excl. BTW)'
                                  : '(incl. BTW)'}
                              </SubTitle>
                              <SubTitle>
                                <NumberFormat>
                                  {totalPsPriceAfterBenefits}
                                </NumberFormat>
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>Uw besparing op 20 jaar</SubTitle>
                              <SubTitle>
                                <NumberFormat>
                                  {cumulativeAnnualSavings[19]}
                                </NumberFormat>
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <BoldTitle>Vandaag te voldoen</BoldTitle>
                              <BoldTitle>€ 100</BoldTitle>
                            </ListLine>
                          </List>
                        )}
                      </div>
                    </Col>
                  </Row>
                </DivLinebreak>
                <div style={{paddingBottom: '30px'}}>
                  <SubTitle align='left' opacity='0.5'>
                    U betaalt vandaag 100 euro en plaatst een vrijblijvende
                    reservatie voor een eigen zelfstroom centrale. De reservatie
                    is <strong>vrijblijvend</strong> en kan op elk moment door u
                    worden terug geroepen, de 100 euro wordt dan terug betaald.
                  </SubTitle>
                </div>
              </div>
            </Col>
            <Col md={{size: 7, order: 1}}>
              <div>
                <div>
                  <BoldTitle align='left'>Accountgegevens</BoldTitle>
                </div>
                <CheckoutForm typeCustomer={typeCustomer} price={10} />
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                  <SubTitle align='left' opacity='0.5'>
                    De reservatie is onder voorbehoud goedkeuring van
                    financiering en goedkeuring tot plaatsing. Na de betaling
                    ontvangt u diezelfde week nog een factuur voor reservatie. U
                    kan steeds kosteloos annuleren. De batterij capaciteit wordt
                    na reservatie in detail bepaald en gedimensioneerd op maat
                    van uw project. De huidige dimensionering is louter
                    informatief.
                  </SubTitle>
                </div>
              </div>
            </Col>
          </Row>
        </Section>
      </Container>
    )
  }
}

export default withRouter(Result)
