import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'

import Navigation from '../../Navigation/Navigation'

const typeHouse = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Welk type woning heeft u?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Appartement'
              name='typeHouse'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={
                props.dataInput.typeHouse === 'Appartement'
              }
            >
              APPARTEMENT
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Gesloten bebouwing'
              name='typeHouse'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHouse === 'Gesloten bebouwing'}
            >
              GESLOTEN BEBOUWING
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Halfopen bebouwing'
              name='typeHouse'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHouse === 'Halfopen bebouwing'}
            >
              HALFOPEN BEBOUWING
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Open bebouwing'
              name='typeHouse'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHouse === 'Open bebouwing'}
            >
              OPEN BEBOUWING
            </CardInput>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default typeHouse
