import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Select from 'react-select'

import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {InputWrapper} from '../../UI/Input/Input'
import {Section} from '../../UI/Section/Section'

const JobTitle = props => {
  const options = [
    {
      name: 'jobTitle',
      optionName: 'jobTitleOption',
      value: 'Zaakvoerder',
      label: 'Zaakvoerder',
    },
    {
      name: 'jobTitle',
      optionName: 'jobTitleOption',
      value: 'Werknemer',
      label: 'Werknemer',
    },
    {
      name: 'jobTitle',
      optionName: 'jobTitleOption',
      value: 'Andere',
      label: 'Andere',
    },
  ]

  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is uw jobtitel?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputWrapper>
              <Select
                value={props.dataInput.jobTitleOption}
                onChange={props.handleChangeSelect}
                options={options}
                isSearchable={false}
                className='data-hj-whitelist'
              />
            </InputWrapper>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default JobTitle
