import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import Navigation from '../../Navigation/Navigation'

import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'
import styled from 'styled-components'

const UnregularCol = styled(Col)`
  @media only screen and (max-width: 990px) {
    margin-left: 0;
    max-width: 100%;
  }
`

const typeHeatSource = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is het type van uw verwarmingsbron?
            </Title>
          </Col>
        </Row>
        <Row>
          <UnregularCol md={{span: 2, offset: 1}}>
            <CardInput
              faIcon=''
              type='radio'
              value='Gas'
              name='typeHeatSource'
              width='170px'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHeatSource === 'Gas'}
            >
              GAS
            </CardInput>
          </UnregularCol>
          <UnregularCol md={2}>
            <CardInput
              faIcon=''
              type='radio'
              value='Warmtepomp'
              name='typeHeatSource'
              width='170px'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHeatSource === 'Warmtepomp'}
            >
              WARMTEPOMP
            </CardInput>
          </UnregularCol>
          <UnregularCol md={2}>
            <CardInput
              faIcon=''
              type='radio'
              value='Mazout'
              name='typeHeatSource'
              width='170px'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHeatSource === 'Mazout'}
            >
              MAZOUT
            </CardInput>
          </UnregularCol>
          <UnregularCol md={2}>
            <CardInput
              faIcon=''
              type='radio'
              value='Elektrisch'
              name='typeHeatSource'
              width='170px'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHeatSource === 'Elektrisch'}
            >
              ELEKTRISCH
            </CardInput>
          </UnregularCol>
          <UnregularCol md={2}>
            <CardInput
              faIcon=''
              type='radio'
              value='Pellets'
              name='typeHeatSource'
              width='170px'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeHeatSource === 'Pellets'}
            >
              PELLETS
            </CardInput>
          </UnregularCol>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          resultValues={props.resultValues}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default typeHeatSource
