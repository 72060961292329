import styled from 'styled-components'
import {Row} from 'react-bootstrap'

export const ValueAmount = styled.span`
  display: block;
  font-size: ${({fontSize}) => fontSize || '62px'};
  font-weight: bold;
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
`

export const ChoiceInputCard = styled.div`
  width: auto;
  height: auto;
  background-color: ${({checked}) => (checked ? '#01a384' : '#ffff')};
  color: ${({checked}) => (checked ? '#ffff' : '#212850')};
  border: ${({checked}) => (checked ? 'none' : '1px solid #a8acc1')};
  line-height: 20px;
  font-size: 20px;
  border-radius: 100px;
  cursor: pointer;

  @media only screen and (max-width: 990px) {
    margin-bottom: 20px !important;
  }
`

export const CardSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
`

export const CardLabel = styled.label`
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const RadioInput = styled.input.attrs(props => ({
  type: 'radio',
  value: props.value,
  name: 'preference',
}))`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
`

export const FullRow = styled(Row)`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  background-color: #fafafc;
  padding-top: 120px;
  padding-bottom: 120px;
`

export const ContentContainer = styled.div`
  padding: ${({padding}) => padding || '0'};
  max-width: 60%;
  margin: 0 auto 3em;
  text-align: center;

  @media only screen and (max-width: 990px) {
    padding: 0 !important;
  }
`

export const TableContainer = styled.div`
  position: relative;
  padding: 0 36px 0 39px;
  text-align: left;
`
