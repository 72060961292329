import React from "react"

import classes from "./Footer.module.css"

const Footer = () => {
  let today = new Date()

  return (
    <div className={classes.LayoutFooterCopyright}>
      <span>©‎ Elugie {today.getFullYear()}</span>
    </div>
  )
}

export default Footer
