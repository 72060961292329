import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'

import Navigation from '../../Navigation/Navigation'

const energySituation = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wat is uw energiesituatie?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Bestaande verbruiker'
              name='energySituation'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={
                props.dataInput.energySituation === 'Bestaande verbruiker'
              }
            >
              BESTAANDE VERBRUIKER
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Nieuwbouw'
              name='energySituation'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.energySituation === 'Nieuwbouw'}
            >
              NIEUWBOUW
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Renovatie'
              name='energySituation'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.energySituation === 'Renovatie'}
            >
              RENOVATIE
            </CardInput>
          </Col>
          <Col md={3}>
            <CardInput
              faIcon=''
              type='radio'
              value='Verhuis'
              name='energySituation'
              className='radio-button'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.energySituation === 'Verhuis'}
            >
              VERHUIS
            </CardInput>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default energySituation
