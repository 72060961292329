import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import HubspotForm from 'react-hubspot-form'

import {withRouter} from 'react-router-dom'
import {
  BoldTitle,
  HeroTitle,
  SecondaryTitle,
  SubTitle,
  Title,
} from '../../UI/Title/Title'
import {Image} from '../../UI/Image/Image'
import {
  ContentContainer,
  FullRow,
  TableContainer,
  ValueAmount,
} from '../Reservation/Styled'
import PSImg from '../../../assets/images/ps-3.jpg'
import {MobileMargin60} from '../../UI/Margin/Margin'
// import {NumberFormat} from '../../../services/numberFormat'
import {Link} from 'react-scroll'
import {Section} from '../../UI/Section/Section'
import happyMapImg from '../../../assets/images/icon-happy-map.svg'
import psSingleImg from '../../../assets/images/ps_single-1.jpg'
import {List, ListLine} from '../../UI/List/List'
import battSingelImg from '../../../assets/images/batt_single-1.jpg'
import Button from '../../UI/Button/Button'
import lodeImg from '../../../assets/images/lode.jpg'
import roelImg from '../../../assets/images/Roel-Van-Hove.jpg'
import dimitriImg from '../../../assets/images/dimitri-de-donder.jpg'
import vbdckImg from '../../../assets/images/VBDCK-001-1.jpg'
import dirkImg from '../../../assets/images/dirk-vandenbergh.jpg'
import jeroenImg from '../../../assets/images/JeroenAnneleen.jpg'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  padding: ${({padding}) => padding || '0'};

  @media only screen and (max-width: 990px) {
    padding: 0 !important;
  }
`
const FormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #f7f7f8;
  padding: 30px;

  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

const Icon = styled.div`
  font-size: 60px;
  color: #01a384;
  text-align: center;
`

export const Fit = () => {
  const typeCustomer = 'Particulier'
  return (
    <>
      <Section paddingTop='60px' paddingBottom='120px'>
        <Container>
          <Row>
            <Col md={12}>
              <ContentContainer>
                <HeroTitle>Proficiat u komt in aanmerking</HeroTitle>
                <SubTitle>
                  Produceer met Elugie uw eigen elektriciteit onafhankelijk van
                  het elektriciteitsnet
                </SubTitle>
              </ContentContainer>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md={12}>
              <Image alt='elugie-power-station' src={PSImg} width='25%' />
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <MobileMargin60>
                <TableContainer>
                  <Icon>
                    <i className='fas fa-plug' />
                  </Icon>
                  <BoldTitle>Controle over uw elektriciteit</BoldTitle>
                  <SubTitle>
                    Elektriciteitsproductie 24/7, 365/365 onafhankelijk van het
                    elektriciteitsnet.
                  </SubTitle>
                </TableContainer>
              </MobileMargin60>
            </Col>
            <Col lg={4} className='border-left'>
              <MobileMargin60>
                <TableContainer>
                  <Icon>
                    <i className='fas fa-piggy-bank' />
                  </Icon>
                  <BoldTitle>Stabiele en laagste energieprijs</BoldTitle>
                  <SubTitle>
                    Tot 30% lagere elektriciteitskost, vermijd CO2 taxatie
                  </SubTitle>
                </TableContainer>
              </MobileMargin60>
            </Col>
            <Col lg={4} className='border-left'>
              <MobileMargin60>
                <TableContainer>
                  <Icon>
                    <i className='fas fa-leaf' />
                  </Icon>
                  <BoldTitle>Maximaal Duurzaam</BoldTitle>
                  <SubTitle>
                    Gebruik enkel groen gas en waterstof uit duurzame bronnen
                  </SubTitle>
                </TableContainer>
              </MobileMargin60>
            </Col>
          </Row>
          <Row style={{textAlign: 'center', marginTop: '48px'}}>
            <Col md={12}>
              <Link to='section1' smooth={true} offset={-70}>
                <Button>MAAK EEN BEL-AFSPRAAK</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section paddingTop='0px' paddingBottom='0px'>
        <FullRow>
          <Container>
            <Row>
              <Col md={12}>
                <ContentContainer>
                  <Title align='center'>
                    Start samen met ons de waterstofrevolutie
                  </Title>
                </ContentContainer>
              </Col>
              <Col lg={3}>
                <Image alt='happy-map' src={happyMapImg} />
              </Col>
              <Col lg={3}>
                <div>
                  <SubTitle fontSize='16px' opacity='0.5'>
                    ELUGIE-CENTRALES
                  </SubTitle>
                  <ValueAmount>450+</ValueAmount>
                </div>
              </Col>
              <Col lg={3}>
                <div>
                  <SubTitle fontSize='16px' opacity='0.5'>
                    HUIDIGE CAPACITEIT IN BELGIË
                  </SubTitle>
                  <ValueAmount>
                    675<sub>kW</sub>
                  </ValueAmount>
                </div>
              </Col>
              <Col lg={3}>
                <div>
                  <SubTitle fontSize='16px' opacity='0.5'>
                    CO2 BESPAARD
                  </SubTitle>
                  <ValueAmount>
                    4.920<sub>ton</sub>
                  </ValueAmount>
                </div>
              </Col>
            </Row>
          </Container>
        </FullRow>
      </Section>
      <Section paddingTop='60px' paddingBottom='60px'>
        <Container>
          <Row style={{margin: '60px 0'}}>
            <Col lg={6}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image
                    style={{
                      width: '100%',
                    }}
                    alt='Lode Fastré'
                    src={lodeImg}
                  />
                ) : (
                  <Image
                    style={{
                      width: '100%',
                    }}
                    alt='Roel Van Hove'
                    src={roelImg}
                  />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} lg={6}>
              <ContentWrapper padding='0 0 0 20px'>
                <Title>Waterstof: 100% duurzame energie</Title>
                <p>
                  De Elugie waterstof elektriciteitscentrale maakt ter plaatse
                  elektriciteit uit water en groengas. Gas uit het net wordt
                  gekraakt tot waterstof, dat op zijn beurt wordt gebruikt om
                  elektriciteit op te wekken. Het eindresultaat is duurzame
                  zelfstroom en een 43% efficiëntere manier van
                  energieproductie.
                </p>
                <Row>
                  <Col md={12}>
                    {typeCustomer !== 'KMO' ? (
                      <span style={{fontStyle: 'italic'}}>
                        “Als ik het vergelijk met een paar jaar geleden, betaal
                        ik nog een derde. Met een jaarverbruik van 30.000 kWh
                        scheelt dat toch al serieus.”
                      </span>
                    ) : (
                      <span style={{fontStyle: 'italic'}}>
                        “We wilden zo veel mogelijk verduurzamen”
                      </span>
                    )}
                  </Col>
                  <Col style={{marginTop: '10px'}} md={12}>
                    {typeCustomer !== 'KMO' ? (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Lode Fastré
                      </BoldTitle>
                    ) : (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Roel Van Hove, BIO-Shop Schilde
                      </BoldTitle>
                    )}
                  </Col>
                </Row>
              </ContentWrapper>
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col lg={{size: 6, order: 12}}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dimitri De Donder Immo Optima'
                    style={{
                      width: '100%',
                    }}
                    src={dimitriImg}
                  />
                ) : (
                  <Image
                    alt='Brouwerij VBDCK-KEREL'
                    style={{
                      width: '100%',
                    }}
                    src={vbdckImg}
                  />
                )}
              </div>
            </Col>
            <Col
              style={{alignItems: 'center', display: 'flex'}}
              lg={{size: 6, order: 1}}
            >
              {typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Volledig onafhankelijk, volledig autonoom</Title>
                  <p>
                    Zelf stroom opwekken betekent onafhankelijkheid: vrijheid
                    van de stijgende stroomprijzen, autonoom uw elektrische
                    wagens opladen, en zekerheid over uw
                    elektriciteitsvoorziening — stil en zorgeloos, elk moment
                    van de dag. Energie uit waterstof is stil, compact en
                    helemaal schoon: de enige uitstoot is waterdamp, en de
                    restwarmte die u produceert is direct toepasbaar in uw
                    vloerverwarming of andere warmtebronnen.
                  </p>

                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Er zijn al verschillende collega’s komen kijken naar
                        onze installatie. Eén van mijn beste vrienden heeft
                        ondertussen dezelfde Elugie centrale gezet.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dimitri De Donder, Immo Optima
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Groeiende besparingen en een hoog rendement</Title>
                  <p>
                    Naast een duidelijke positieve impact op het milieu, zorgt
                    uw Elugie centrale ook voor aanzienlijke besparingen. De
                    leasing of aankoop van de centrale wordt betaald met de
                    besparing die wordt gerealiseerd en op een periode van
                    15jaar bespaar je meer dan 30% op kosten voor elektriciteit.
                    Als u uw factuur blijft betalen ben je je centen kwijt.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Zelfs de restwarmte van de centrales gebruiken we in
                        ons brouwproces”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Philippe De Cock, Brouwerij VBDCK-KEREL
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col lg={6}>
              <div>
                {typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dirk Vandenbergh'
                    style={{
                      width: '100%',
                    }}
                    src={dirkImg}
                  />
                ) : (
                  <Image
                    alt='Jeroen Anneleen'
                    style={{
                      width: '100%',
                    }}
                    src={jeroenImg}
                  />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} lg={6}>
              {typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Besparingen op uw energierekening</Title>
                  <p>
                    De Belgische kernuitstap drijft stroomprijzen op, en
                    toenemend gebruik van duurzame bronnen zet het stroomnet
                    verder onder druk. Het resultaat is — al jarenlang —
                    stijgende stroomprijzen. Een zelfstroom centrale verzekert u
                    van een goedkope energievoorziening, in eigen beheer.
                    Ontkoppeling van stroomprijzen is vooral interessant voor
                    gebruikers met bijvoorbeeld een elektrisch wagenpark, een
                    warmtepomp of een anderszins bovengemiddeld energiegebruik.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Juist afrekening gehad van elektriciteit en vlotjes 67%
                        minder verbruik dan vorig jaar! Dus supertevreden klant!
                        Ik kan alleen maar merci zeggen aan gans uw equipe toffe
                        gasten!”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dirk Vandenbergh
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Volledige onafhankelijkheid</Title>
                  <p>
                    Uw Elugie waterstofcentrale komt met een stevige set
                    batterijen. Hiermee slaat u overschotten voor de korte
                    termijn op, zodat u ook op dure piekuren niet uit het net
                    hoeft te tappen. Met de invoering van smart meters en nieuwe
                    regelgeving in 2021 wordt elke gebruiker beloond om het
                    stroomnet minimaal te belasten. Met een Elugie centrale bent
                    u volledig onafhankelijk, en ondersteunt u deze duurzame
                    revolutie.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Stijgende energieprijzen wegen niet meer op de toekomst
                        van onze zaak.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Slagerij Jeroen en Anneleen-Oudenburg
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
          <Row style={{textAlign: 'center', marginTop: '48px'}}>
            <Col md={12}>
              <Link to='section1' smooth={true} offset={-70}>
                <Button>MAAK EEN BEL-AFSPRAAK</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section paddingTop='120px' paddingBottom='120px'>
        <Container>
          <Row>
            <Col md={12}>
              <ContentContainer>
                <Title align='center'>Eigenschappen</Title>
                <SubTitle>Technische gegevens van productie en opslag</SubTitle>
              </ContentContainer>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div style={{padding: '0 16px'}}>
                <TableContainer>
                  <Image
                    alt='elugie-power-station'
                    width='50%'
                    margin='0 0 14px 0'
                    display='inline-block'
                    src={psSingleImg}
                  />
                </TableContainer>
              </div>
              <div>
                <TableContainer>
                  <SecondaryTitle>Elugie-centrale</SecondaryTitle>
                  <List>
                    <ListLine>
                      <BoldTitle align='left'>Productie</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- 1.5 kW - 13000 kWh elektriciteit / jaar</span>
                    </ListLine>
                    <ListLine>
                      <span>- 0.75 kW - 6500 kWh warmte / jaar</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Verbruik</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- 22000 kWh verbruik gas of groengas / jaar</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Technologie</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- Waterstoftechnologie (SOFC)</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>
                        Installatie (per centrale)
                      </BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- 120cm(h), 55cm(b), 80cm(l)</span>
                    </ListLine>
                    <ListLine>
                      <span>- 250 kg</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Service en garantie:</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- 10 tot 20 jaar</span>
                    </ListLine>
                  </List>
                </TableContainer>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <TableContainer>
                  <Image
                    alt='elugie-power-station'
                    width='50%'
                    margin='0 0 14px 0'
                    display='inline-block'
                    src={battSingelImg}
                  />
                </TableContainer>
              </div>
              <div className='border-left'>
                <TableContainer>
                  <SecondaryTitle>20kVA-28kWh opslag</SecondaryTitle>
                  <List>
                    <ListLine>
                      <BoldTitle align='left'>Netaansluiting</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- Nominal power 28kW</span>
                    </ListLine>
                    <ListLine>
                      <span>- 400 VAC 3-fase TN-S, TT</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Technologie</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- Lithium-ion</span>
                    </ListLine>
                    <ListLine>
                      <span>- 27.9 kWh</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Installatie</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- Indoor</span>
                    </ListLine>
                    <ListLine>
                      <span>- 1800cm(h), 600cm(b), 750cm(l)</span>
                    </ListLine>
                    <ListLine>
                      <span>- 520 kg</span>
                    </ListLine>
                    <ListLine>
                      <BoldTitle align='left'>Garantie</BoldTitle>
                    </ListLine>
                    <ListLine>
                      <span>- Tot 10 jaar garantie</span>
                    </ListLine>
                    <ListLine>
                      <span>- Verwachte levensduur tot 20 jaar</span>
                    </ListLine>
                  </List>
                </TableContainer>
              </div>
            </Col>
          </Row>
          {/*<Row style={{textAlign: 'center', marginTop: '48px'}}>*/}
          {/*  <Col md={12}>*/}
          {/*    <BoldTitle align='center' fontSize='23px'>*/}
          {/*      Vanaf <NumberFormat>438.30</NumberFormat> / maand*/}
          {/*    </BoldTitle>*/}
          {/*    <SubTitle align='center'>*/}
          {/*      of <NumberFormat>46540.20</NumberFormat>*/}
          {/*    </SubTitle>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row style={{textAlign: 'center', marginTop: '48px'}}>
            <Col md={12}>
              <Link to='section1' smooth={true} offset={-70}>
                <Button>MAAK EEN BEL-AFSPRAAK</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section paddingTop='120px' paddingBottom='120px' id='section1'>
        <Container>
          <Row>
            <Col md={12}>
              <div>
                <Title align='center'>
                  Maak een afspraak voor een offerte op maat
                </Title>
                <SubTitle>
                  Binnen de 20 minuten duidelijkheid wat zelfstroom uit
                  waterstof voor u kan betekenen
                </SubTitle>
              </div>
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg={{size: 5, order: 12}}>
              <FormWrapper>
                <HubspotForm
                  portalId='4356494'
                  formId='b1a630e2-09bc-4396-bf9d-01e3a50e47f5'
                  loading={<div>Loading...</div>}
                />
              </FormWrapper>
            </Col>
            <Col lg={{size: 7, order: 1}}>
              <ul className='timeline'>
                <li>
                  <BoldTitle align='left'>Bel afspraak</BoldTitle>
                  <p>
                    Onze energie expert zal u binnen de 48 uren contacteren om
                    alle uw vragen te beantwoorden.
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>Maak uw reservatie</BoldTitle>
                  <p>
                    Reserveer voor 100 euro uw eigen Elugie-centrale met of
                    zonder batterij systeem.
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>Beoordeling en meting</BoldTitle>
                  <p>
                    <span>
                      Een lokale installateur komt bij u langs voor opmeting
                      uitvoering en er wordt een energie meter geplaatst om uw
                      verbruiksprofiel op te meten.
                    </span>
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>
                    Offerte op maat incl. plaatsing
                  </BoldTitle>
                  <p>
                    <span>
                      U ontvang van ons een offerte op maat inc. plaatsing,
                      samen met prognoses van uw financiële rendement op 5, 10
                      en 20 jaar. Dat biedt overzicht en zekerheid.
                    </span>
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>Bestelling en financiering</BoldTitle>
                  <p>
                    <span>
                      U bestelt uw eigen Elugie-centrale via cashaankoop, eigen
                      financiering of de Elugie renting formule.
                    </span>
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>Levering en installatie</BoldTitle>
                  <p>
                    <span>
                      Wij zorgen voor levering en professionele installatie van
                      uw Elugie centrale alsook voor administratieve begeleiding
                      van meldingen en subsidie aanvragen.
                    </span>
                  </p>
                </li>
                <li>
                  <BoldTitle align='left'>
                    Start zelfstroom met Elugie
                  </BoldTitle>
                  <p>
                    <span>
                      Start met ons de waterstofrevolutie en geniet van uw eigen
                      elektriciteitsproductie.
                    </span>
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default withRouter(Fit)
