import React, {Component} from 'react'
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import {validateEmail} from '../../../helpers'

import Button from '../../UI/Button/Button'

import axios from 'axios'
import {SubTitle, Title} from '../../UI/Title/Title'
import styled from 'styled-components'
import {Input} from '../../UI/Input/Input'
import {Section} from '../../UI/Section/Section'
import {apiUrl} from '../../../index'

const FormWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #f7f7f8;
  padding: 30px;

  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

const FormGroup = styled.div`
  margin-bottom: 1rem;

  &:focus {
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    box-shadow: 0 2px 11px 0 rgba(32, 33, 36, 0.1);
    outline: 0;
  }
`

const InputValue = styled(Input)`
  width: 100%;
  margin-bottom: 10px;
`

const ElugieGdpr = styled.div`
  padding-top: 2.9rem;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  font-size: 12px;
  color: #d8d8d8;

  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

class UserForm extends Component {
  state = {
    loading: false,
  }

  getResults = async e => {
    e.preventDefault()
    e.persist()
    this.setState({loading: true})
    const {
      currentConsumption,
      floorHeating,
      electricBoiler,
      typeHeatSource,
      firstname,
      lastname,
      email,
      zipcode,
      placeName,
      province,
      jobTitle,
      industry,
      inverterPower,
      vehicleEH,
      highVoltageCabine,
      solarAvailable,
      connectionGas,
      estimatedKWhConsumption,
      ageBuilding,
      poolAvailable,
      typeHouse,
      ownerOrRenting,
      energySituation,
      airHeatPumpAvailable,
      typeCustomer,
      profession,
    } = this.props.dataInput

    let VATEnergy
    let EstimatedkWh

    if (estimatedKWhConsumption) {
      EstimatedkWh = estimatedKWhConsumption
    } else {
      if (typeCustomer === 'KMO' || typeCustomer === 'Overheid') {
        VATEnergy = 0
        EstimatedkWh = Math.round((currentConsumption * 12) / 0.23)
      } else {
        VATEnergy = 0.21
        EstimatedkWh = (currentConsumption * 12) / (0.23 * (VATEnergy + 1))
      }
    }

    let data = {
      first_name: firstname,
      last_name: lastname,
      email: email,
      city: placeName,
      postal_code: zipcode,
      amount_kwh_usage: parseInt(EstimatedkWh),
      high_voltage_cabin: highVoltageCabine === 'Yes',
      gas_connection: typeHeatSource === 'Gas' ? 'Gas ok' : connectionGas,
      type_heat_source: typeHeatSource,
      floor_heating: floorHeating === 'Yes',
      electric_vehicle: vehicleEH === 'Yes',
      electric_boiler: electricBoiler === 'Yes',
      age_building: ageBuilding,
      pool_available: poolAvailable === 'Yes',
      type_house: typeHouse,
      owner_or_renter: ownerOrRenting,
      solar_panels: solarAvailable === 'Yes',
      energy_situation: energySituation,
      air_heat_pump_available: airHeatPumpAvailable === 'Yes',
      solar_panels_power: inverterPower === '' ? 0 : parseFloat(inverterPower),
      province: province,
      industry: industry,
      type_customer: typeCustomer,
      profession: profession,
      job_title: jobTitle,
      hutk: Cookies.get('hubspotutk') ? Cookies.get('hubspotutk') : '',
    }

    try {
      await axios({
        method: 'POST',
        url: `${apiUrl}/v1/hubspot/submit-form`,
        data: data,
        withCredentials: true,
      })

      // End routes to send users to different types of pages
      if (
        this.props.dataInput.connectionGas === 'Geen gas' ||
        (EstimatedkWh <= 9999 && typeCustomer === 'KMO') ||
        (EstimatedkWh <= 9999 && typeCustomer === 'Particulier') ||
        (EstimatedkWh <= 9999 && typeCustomer === 'Vrij beroep') ||
        (typeHeatSource === 'Elektrisch' && EstimatedkWh <= 24999) ||
        (typeHeatSource === 'Warmtepomp' && EstimatedkWh <= 20000)
      ) {
        Cookies.remove('elugie_sim')
        window.location.assign('https://elugie.com/simulator/never/')
      } else if (EstimatedkWh > 150000) {
        Cookies.remove('elugie_sim')
        window.location.assign('https://elugie.com/simulator/later/')
      } else if (highVoltageCabine === 'Yes') {
        Cookies.remove('elugie_sim')
        window.location.assign('https://elugie.com/simulator/later/')
      } else {
        Cookies.set('elugie_sim', {typeCustomer})
        this.props.history.push(`/fit`)
      }
      this.setState({loading: false})
    } catch (error) {
      this.setState({loading: false})
      Cookies.remove('elugie_sim')
      Sentry.withScope(scope => {
        scope.setExtra('data', data)
        Sentry.captureException(error)
      })
    }
  }

  render() {
    const disbaleButon =
      this.props.dataInput.firstname &&
      this.props.dataInput.lastname &&
      validateEmail(this.props.dataInput.email) &&
      !this.state.loading

    return (
      <Container>
        <Section>
          <Row>
            <Col md={12}>
              <SubTitle>
                Vul de onderstaande info in om uw resultaten te verkrijgen
              </SubTitle>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title align='center' marginBottom='1rem' marginTop='1rem'>
                U bent er bijna!
              </Title>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormWrapper>
                <form onSubmit={this.getResults}>
                  <FormGroup>
                    <InputValue
                      type='text'
                      placeholder='Voornaam'
                      name='firstname'
                      value={this.props.dataInput.firstname}
                      onChange={this.props.onChangeHandler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputValue
                      type='text'
                      placeholder='Naam'
                      name='lastname'
                      value={this.props.dataInput.lastname}
                      onChange={this.props.onChangeHandler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputValue
                      type='email'
                      placeholder='Email'
                      name='email'
                      value={this.props.dataInput.email}
                      onChange={this.props.onChangeHandler}
                    />
                  </FormGroup>
                  <Col style={{textAlign: 'center'}} md={12}>
                    <Button disabled={!disbaleButon}>
                      {this.state.loading ? (
                        <div>
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                          Loading..
                        </div>
                      ) : (
                        'TOON RESULTATEN'
                      )}
                    </Button>
                  </Col>
                </form>
              </FormWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ElugieGdpr>
                Elugie heeft de door jou verstrekte contactgegevens nodig om
                contact met je op te nemen over onze producten en diensten. Je
                kunt je op elk moment afmelden voor deze berichten. Bekijk ons{' '}
                <a
                  href='https://elugie.com/privacyverklaring/?hsCtaTracking=aae637bd-ce54-4a21-81b7-838d920ef7e1%7C0ca46b07-11e5-46aa-ad3f-2b29781c7952'
                  rel='nofollow'
                >
                  privacybeleid
                </a>{' '}
                voor meer informatie over hoe je je af kan melden, onze
                privacypraktijken en hoe we ons inzetten om je privacy te
                beschermen en respecteren.
              </ElugieGdpr>
            </Col>
          </Row>
        </Section>
      </Container>
    )
  }
}

export default withRouter(UserForm)
