
export const NumberFormat = props => {
return new Intl.NumberFormat('nl-BE', {
  style: 'currency',
  currency: 'EUR',
}).format(Math.abs(props.children))
}



export const NumberFormatWithOutAbs = props => {
  return new Intl.NumberFormat('nl-BE', {
    style: 'currency',
    currency: 'EUR',
  }).format(props.children)
}