import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import {Container} from 'react-bootstrap'
import Helmet from 'react-helmet'

import LogoImg from '../../assets/images/logo_elugie_rgb.png'
import classes from './Header.module.css'

const Header = props => {
  const pathName = props.history.location.pathname.split('/')[1]
  let title
  let progressBar = 0
  switch (pathName) {
    case 'zipcode':
      progressBar = 0
      title = 'Elugie - Wat is uw postcode?'
      break
    case 'type-customer':
      progressBar = 7
      title = 'Elugie - Wie bent u?'
      break
    case 'know-the-usage':
      progressBar = 14
      title = 'Elugie - Kent u uw elektriciteitsverbruik in kWh?'
      break
    case 'estimated-kWh-consumption':
      progressBar = 21
      title = 'Elugie - Mijn verbruik kWh'
      break
    case 'current-consumption':
      progressBar = 28
      title =
        'Elugie - Hoeveel betaalt u maandelijks voor uw elektriciteitsverbruik?'
      break
    case 'type-heat-source':
      progressBar = 35
      title = 'Elugie - Wat is het type van uw verwarmingsbron?'
      break
    case 'connection-gas':
      progressBar = 40
      title = 'Elugie - Heeft u een gasaansluiting?'
      break
    case 'air-heat-pump-available':
      progressBar = 41
      title = 'Elugie - Heeft u een gasaansluiting?'
      break
    case 'floor-heating':
      progressBar = 42
      title = 'Elugie - Heeft u vloerverwarming?\n'
      break
    case 'electric-boiler':
      progressBar = 49
      title = 'Elugie - Heeft u een elektrische boiler?\n'
      break
    case 'vehicle-e-h':
      progressBar = 56
      title = 'Elugie - Heeft u een elektrische/hybride wagen?\n'
      break
    case 'energy-situtation':
      progressBar = 64
      title = 'Elugie - Wat is uw energie situatie?'
      break
    case 'owner-or-renting':
      progressBar = 71
      title = 'Elugie - Bent u de eigenaar of huurt u?'
      break
    case 'industry':
      progressBar = 75
      title = 'Elugie - Wat is uw activiteit?'
      break
    case 'age-building':
      progressBar = 78
      title = 'Elugie - Hoe oud is uw gebouw?'
      break
    case 'pool-available':
      progressBar = 85
      title = 'Elugie - Heeft u een zwembad?'
      break
    case 'type-house':
      progressBar = 92
      title = 'Elugie - Welk type woning heeft u?'
      break
    case 'profession':
      progressBar = 92
      title = 'Elugie - Wat is uw activiteit?'
      break
    case 'solar-available':
      progressBar = 94
      title = 'Elugie - Heeft u zonnepanelen?' + ''
      break
    case 'inverter-power':
      progressBar = 98
      title = 'Elugie - Wat is het vermogen van uw PV-installatie?'
      break
    case 'high-voltage-cabine':
      progressBar = 84
      title = 'Elugie - Heeft u een hoogspanningscabine?'
      break
    case 'job-title':
      progressBar = 91
      title = 'Elugie - Wat is uw jobtitel?'
      break
    case 'user-form':
      progressBar = 100
      title = 'Elugie - Formulier'
      break
    case 'result':
      title = 'Elugie - Result'
      break
    case 'reservation':
      title = 'Elugie - Reservatie'
      break
    case 'fit':
      title = 'Elugie - Proficiat u komt in aanmerking'
      break
    default:
      progressBar = 0
      break
  }

  let navItems = false

  if (props.location.state) {
    navItems = props.location.state.navItems
  }

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <header className={classes.SiteHeader} role='banner'>
        <div className={classes.BreadCrumb}>
          <div
            style={{width: progressBar + '%'}}
            className={classes.BreadCrumbBar}
          />
        </div>
        <Container>
          <div className={classes.SiteHeaderRow}>
            <div className={classes.SiteHeaderLogoWrapper}>
              <a href={'/zipcode'}>
                <img
                  alt='Elugie'
                  className={classes.Logo}
                  height={53}
                  width={173}
                  src={LogoImg}
                />
              </a>
            </div>
            {navItems && (
              <div className={classes.SiteHeaderHomeLink}>
                <a href='https://elugie.com/'> Terug naar de website </a>
              </div>
            )}
          </div>
        </Container>
      </header>
    </Fragment>
  )
}

export default withRouter(Header)
