import styled from 'styled-components'

export const HeroTitle = styled.h1`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  text-align: center;
  margin-bottom: 12px;
`
export const Title = styled.h2`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  font-size: 30px;
  margin-bottom: ${({marginBottom}) => marginBottom || '15px'};
  margin-top: ${({marginTop}) => marginTop || '1px'};
  text-align: ${({align}) => align || 'left'};

  @media only screen and (max-width: 400px) {
    padding-top: 30px;
  }

  @media only screen and (max-width: 1020px) and (min-width: 400px) {
    padding-top: 30px;
  }
`
export const SecondaryTitle = styled.h3`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  font-size: 25px;
  margin-bottom: ${({marginBottom}) => marginBottom || '15px'};
  margin-top: ${({marginTop}) => marginTop || '1px'};
  text-align: ${({align}) => align || 'left'};
`
export const SubTitle = styled.p`
  color: ${({color}) => color || '#212850'};
  font-size: ${({fontSize}) => fontSize || '18px'};
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
  margin-bottom: ${({marginBottom}) => marginBottom || '0'};
  margin-top: ${({marginTop}) => marginTop || '8px'};
`

export const BoldTitle = styled(SubTitle)`
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: bold;
`
