import styled from 'styled-components'

export const List = styled.ul`
  padding-left: 0;
`
export const ListLine = styled.li`
  font-size: 18px;
  //line-height: 35px;
  clear: both;
  padding: 5px;
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`

export const DivLinebreak = styled.div`
  border-top: 0.5px solid #c6c9d7;
  padding-top: ${({paddingTop}) => paddingTop || '20px'};
  margin-top: 10px;
  padding-bottom: ${({paddingBottom}) => paddingBottom || '15px'};
`

// export const ValueAmount = styled.span`
//   display: block;
//   font-size: ${({fontSize}) => fontSize || '62px'};
//   font-weight: bold;
//   text-align: ${({align}) => align || 'center'};
//   opacity: ${({opacity}) => opacity || '1'};
// `
